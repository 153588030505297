import { React, Support, Helper, DropDown, TextInput, DatePicker } from "common";

const CobrPersonalDetails = (props) => {

    const { row, errors, onInputChanged, onBlurError, onInputClicked } = props;

    let rootpath = "coborrowerpersonaldetails";

    let MonthlyIncomeTypes = Support.MonthlyIncomeTypes();
    Helper.AddFieldOption(MonthlyIncomeTypes, 1, 8, "No Income");

    return (
        <>
            <div className="applyHead_borderBottom">
                Co-Borrower Personal Details
            </div>
            <ul className="contentIIIcol">
                <li className="contentIIIcol">
                    <div className="applyContentHead">First Name</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.firstName`}
                            value={row?.firstName}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={'First Name is required'}
                            dataRequired={true}
                            required={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Middle Name</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.middleName`}
                            value={row?.middleName}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Last Name</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.lastName`}
                            value={row?.lastName}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={'Last Name is required'}
                            dataRequired={true}
                            required={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Phone Number</div><div className="formMandatory">*</div>
                    {/* <div className="applyNoteText">OTP Will Be Sent</div> */}
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.phoneNumber`}
                            valuetype={"PHONE"}
                            value={row?.phoneNumber}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={'Phone Number is required'}
                            validationMessage={'Enter valid Phone Number'}
                            dataRequired={true}
                            validate={true}
                            required={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Email Address</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.emailAddress`}
                            valuetype={"EMAIL"}
                            value={row?.emailAddress}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            validationMessage={'Enter valid email-id'}
                            dataRequired={false}
                            validate={true}
                            required={false}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">DOB</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <DatePicker
                            errors={errors}
                            path={`${rootpath}.birthDate`}
                            placeholder="Date Of Birth"
                            value={row?.birthDate}
                            valuetype={'DOB'}
                            age={18}
                            dateFormat="dd/MM/yyyy"
                            required={true}
                            requiredMessage={'Date of Birth is required'}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Credit Score</div><div className="formMandatory">*</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.creditscore`}
                            value={row?.creditscore}
                            onInputChildChanged={onInputChanged}
                            items={Support.CreditTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                            dataRequired={true}
                            indexValue={1}
                            requiredMessage={"Credit Score is required"}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Marital Status</div><div className="formMandatory">*</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.maritalStatus`}
                            value={row?.maritalStatus}
                            onInputChildChanged={onInputChanged}
                            items={Support.MaritalTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                            dataRequired={true}
                            indexValue={1}
                            requiredMessage={"Marital Status is required"}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Gender</div><div className="formMandatory">*</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.gender`}
                            value={row?.gender}
                            onInputChildChanged={onInputChanged}
                            items={Support.GenderTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                            dataRequired={true}
                            indexValue={1}
                            requiredMessage={"Gender is required"}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Education</div><div className="formMandatory">*</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.education`}
                            value={row?.education}
                            onInputChildChanged={onInputChanged}
                            items={Support.EducationTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                            dataRequired={true}
                            indexValue={1}
                            requiredMessage={"Education is required"}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">PAN</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.pan`}
                            valuetype={"PAN"}
                            value={row?.pan}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={'PAN is required'}
                            validationMessage={'Enter valid PAN'}
                            dataRequired={false}
                            required={false}
                            validate={true}
                            uppercase={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Aadhar</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.aadhar`}
                            valuetype={"AADHAR"}
                            value={row?.aadhar}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={'Aadhar is required'}
                            validationMessage={'Enter valid Aadhar'}
                            dataRequired={true}
                            required={true}
                            validate={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Net Monthly Income</div><div className="formMandatory">*</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.monthlyIncome`}
                            value={row?.monthlyIncome}
                            onInputChildChanged={onInputChanged}
                            items={MonthlyIncomeTypes}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                            dataRequired={true}
                            indexValue={1}
                            requiredMessage={"Net Monthly Income is required"}
                        />
                    </div>
                </li>
            </ul>
            <div className="spacer_20margin_bottom"></div>
        </>
    );
}

export default CobrPersonalDetails;