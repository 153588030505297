import { React, Link, Container, useState, useEffect, TextInput, Helper } from "common";
import { RatingStar, UserRating } from "./childs";
import { CommonApi } from "services";


const Component = () => {
    const [initlized, setInitlized] = useState(false);
    const [row, setRow] = useState({});
    const [ratingList, setRatingList] = useState([]);
    const [state, setState] = useState(false);
    const [success, setSuccess] = useState(null);
    const [rating, setRating] = useState(0);

    const [errors, setErrors] = useState([]);
    const [error, setError] = useState(null);

    const OnBlurError = (name, val, clear) => {
        setSuccess(null);
        let _errors = [];
        if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
        if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
        setErrors(_errors);
        setError(val);
    };

    const OnInputChanged = async (path, value, index) => {
        let newRow = Helper.ModifyJsonArrayObject4(row, path, value);
        setRow(newRow);
        setState(!state);
    }

    const OnInputClicked = (name) => {
        setSuccess(null);
        let _err = null;
        if (errors && errors.length > 0) {
            let _findIndex = errors.findIndex((x) => x.field === name);
            if (_findIndex > -1) {
                _err = errors[_findIndex].value;
            }
        }
        setError(_err);
    };

    const OnRecordValidate = () => {
        setError("");
        setErrors([]);
        const { errors, valid } = Helper.IsFormValid('container');

        if (!valid) {
            setErrors(errors);
            setError("Fill all required details");
            return false;
        }
        return true;
    };

    const OnSubmitClicked = async (e) => {
        e.preventDefault();
        if (parseInt(rating) < 1) {
            setError("Please select Rating");
            return;
        }
        if (!row.review) {
            setError("Review is required");
            return;
        }
        if (!OnRecordValidate()) return;
        row.rating = rating;
        global.Busy(true);
        let rslt = await CommonApi.AddReviews(row);
        global.Busy(false);
        if (rslt.status !== 100) {
            setErrors(rslt.errors);
            setError(rslt.statusText);
            return;
        } else {
            setSuccess("We're reviewing your feedback before it's published on the website.");
            setRow({ review: "", name: "", rating: "" });
            setRating(0)
        }
    };


    const FetchResults = async () => {
        global.Busy(true);
        let rslt = await CommonApi.GetReviews();
        if (rslt.status === 100) {
            setRatingList(rslt.data);
        }
        global.Busy(false);
    };

    const RenderNewRating = () => {
        return (
            <div>
                <ul className='newRatingVerBlk'>
                    <li className='newRatingStars'>
                        {[...Array(5)].map((_, index) => (
                            <span
                                key={index}
                                style={{ cursor: 'pointer', color: index < rating ? '#004466' : '#d3d3d3', fontSize: '1.75rem' }}
                                onClick={() => setRating(index + 1)}
                            >
                                &#9733;
                            </span>
                        ))}
                    </li>
                    <li className='newRatingCount'>
                        You rated: {rating} out of 5
                    </li>
                </ul>
            </div>
        );
    }

    if (initlized) {
        setInitlized(false);
        setErrors([]);
        setError(null);
        setSuccess(null);
        FetchResults();
    }

    useEffect(() => {
        setInitlized(true);
    }, []);

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_gold">
                            <div className="inPgBcShadeBlk">
                                <div className="inPgBcCrossBlk">
                                    <div className="widthfix_10px">
                                        <ul className="bc_IIcol">
                                            <li className="bc_IIcol">
                                                <div className="inPgBcHalfBlk">
                                                    <div className="inPgBcTextBlk">
                                                        <Link to="/loans"><div className="bcMainTxt">Loans</div></Link>
                                                        <Link to="/goldloan"><div className="bcSubTxt">/ Gold Loan</div></Link>
                                                        <div className="bcSubTxt">/ Reviews</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="bc_IIcol">
                                                <ul className="bcPointsSlanting">
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Interest rates starts from 65* paisa per month</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Higher Loan Amount, Depending on Purity</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Instant Cash from a Wide Range of Schemes</li>
                                                </ul>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="loanInpg">
                            <div className="widthfix_10px">
                                <div className="glReviewSummary">
                                    <div className="glReviewSummaryHead">Average Ratings</div>
                                    <RatingStar />
                                </div>



                                <ul className="glReviewIIcol">
                                    <li className="glReview_Form">
                                        <div className="glReviewHeading">Your input matters! We're always striving to improve. Could you please take a moment to share your feedback with us? Your thoughts help us enhance our services to better meet your needs.</div>
                                        <div className="glReviewHead">Overall Rating<div className="formMandatory">*</div></div> <div className="glReviewContentBlk">
                                            <RenderNewRating />
                                        </div>

                                        <div className="glReviewHead">Name<div className="formMandatory">*</div></div>

                                        <div className="glReviewContentBlk">
                                            <div className="glNewRatingTxtField">
                                                <TextInput
                                                    path={`name`}
                                                    value={row?.name}
                                                    onInputChildChanged={OnInputChanged}
                                                    onBlurError={OnBlurError}
                                                    onInputClicked={OnInputClicked}
                                                    errors={errors}
                                                    requiredMessage={'Name is required'}
                                                    dataRequired={true}
                                                    required={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="glReviewHead">Write a Review<div className="formMandatory">*</div></div>
                                        <div className="glReviewContentBlk">
                                            <div className="glNewRatingTxtArea">
                                                <textarea
                                                    path={`review`}
                                                    value={row?.review}
                                                    onInput={(e) => OnInputChanged('review', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="glNewRatingBtnBlk">
                                            <input
                                                type="button"
                                                value="Submit"
                                                className="reviewSubmitBtn"
                                                onClick={(e) => OnSubmitClicked(e)}
                                            />
                                        </div>
                                        {/* After submit show the following */}
                                        {error && <div className="errormessage">{error}</div>}
                                        {success && <div className="glNewRatingNote">Thank you for your feedback. It will go live soon.</div>}
                                    </li>
                                    <li className="glReview_Content">
                                        <ul className="glUserReviews">

                                            {ratingList && ratingList.length > 0 && (
                                                ratingList.map((x, index) => {
                                                    return (
                                                        <li key={index} className="glUserReviews">
                                                            <ul className="glNameDateBlk">
                                                                <li className="glNameDateBlk">
                                                                    <div className="glReviewerName">{x.name}</div>
                                                                    <div className="glReviewerRatedStarBlk"><UserRating rating={x.rating} /></div>
                                                                </li>
                                                                <li className="glNameDateBlk">
                                                                    Reviewed On:<br />{Helper.ToDate(x.createdAt, "DD/MMM/YYYY")}
                                                                </li>
                                                            </ul>
                                                            <div className="glReviewContent">{x.review}</div>
                                                        </li>
                                                    )
                                                })
                                            )}
                                        </ul>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container >
        </>
    )
}

export default Component;