import {
    React, Link, Container, Helper, useNavigate,
    useState, useEffect, DoughnutChart, Session
} from "common";

import { FinstaApi } from "services";

import DialogContainer from "screens/shared/popups/dialog";

const RenderLoanPortfolio = (props) => {
    const { counts } = props;

    const [accountSummary, setAccountSummary] = useState({ percent: 0, values: [0, 0] });

    useEffect(() => {
        let percent = '0%';
        if (counts && counts.total > 0) {
            percent = parseInt((counts.paid / counts.total) * 100) + '%';
        }
        const paidPercent = parseInt((counts.paid / counts.total) * 100)
        if (paidPercent > 100) {
            setAccountSummary({ values: [0, 100], percent });
        } else {
            const leftPercent = 100 - paidPercent
            setAccountSummary({ values: [leftPercent, paidPercent], percent });
        }
    }, [counts]);

    return (
        <div className="dash_shadowBlk">
            <div className="dashCornerRed"></div>
            <div className="dashBlkHead">Loan Portfolio
                <div className="dashBlkSupportHead">as on {Helper.ToDate(new Date(), "DD MMM YYYY")}</div>
                {/* {counts && counts.total > 0 && <div className="dashBlkSupportSubHead">Total Loan Amount: Rs.{Helper.INRCurrencyFormat(counts?.total, true)}</div>} */}
            </div>
            <div className="dash_Portfolio">
                {counts && counts.total > 0 ? (
                    <>
                        <ul className="dash_portfolio_IIcol">
                            <li className="dash_portfolio_IIcol">
                                <DoughnutChart chartText={`${accountSummary.percent}\nPaid`} values={accountSummary.values} />
                            </li>
                            <li className="dash_portfolio_IIcol">
                                <div className="dash_portfolio_colorBlue"></div>
                                <div className="dash_portfolio_chartTitle">Amount Paid</div>
                                <div className="dash_portfolio_chartAmount">Rs.{Helper.INRCurrencyFormat(counts.paid, true)}</div>
                                <div className="dash_portfolio_colorRed"></div>
                                <div className="dash_portfolio_chartTitle">Outstanding Amount</div>
                                <div className="dash_portfolio_chartAmount">Rs. {Helper.INRCurrencyFormat(counts.balance, true)}</div>
                            </li>
                        </ul>
                    </>
                ) : (
                    <div className="dashNoLoan">
                        <div className="dashNoLoan_txt">You do not have any active loans.</div>
                        <div className="dashNoLoan_txt">Select loan products to fulfill your financial needs</div>
                        <Link to="/apply"><input type="button" className="ddashNoLoan_button" value="Apply Now" /></Link>
                    </div>
                )}
            </div>
        </div>
    );
}

const RenderLoanAccounts = (props) => {
    const { accounts } = props;
    const [selectedAccount, setSelectedAccount] = useState("");
    const [selectedAccountItem, setSelectedAccountItem] = useState({});
    const [accountSummary, setAccountSummary] = useState({ percent: "", values: [0, 0] });

    const OnSelectInputChanged = (e) => {
        let _value = e.target.value;
        setSelectedAccount(_value);
        let tmp = accounts.find((x) => x.loanNumber === _value) || { emi: 0, tenure: 0, numEmiPaid: 0 };
        setSelectedAccountItem(tmp);

        const total = parseFloat(tmp.emi) * parseInt(tmp.tenure);
        const bal = parseFloat(tmp.emi) * parseInt(tmp.tenure - tmp.numEmiPaid);
        const paid = parseFloat(tmp.emi) * parseInt(tmp.numEmiPaid);
        const percent = parseInt((paid / total) * 100) + "%";
        let paidPercent = parseInt((paid / total) * 100)
        if (paidPercent > 100) {
            setAccountSummary({ values: [0, 100], percent });
        } else {
            const leftPercent = 100 - paidPercent
            setAccountSummary({ values: [leftPercent, paidPercent], percent });
        }

    }

    useEffect(() => {
        if (accounts && accounts.length) {
            OnSelectInputChanged({ target: { value: accounts[0].loanNumber } });
        }
    }, [accounts]);

    return (
        <div className="dash_shadowBlk">
            <div className="dashCornerRed"></div>
            <div className="dash_LA">
                <div className="dashSelectLoanHead">Loan Account:</div>
                {accounts && accounts.length > 0 ? (
                    <>

                        <div className="dashSelectLoanAccount">
                            <select name="dpLoanAccount" id="dpLoanAccount"
                                onChange={(e) => OnSelectInputChanged(e)}
                                value={Helper.ToString(selectedAccount)} >
                                <option value="" disabled>{accounts.length} Accounts</option>
                                {
                                    accounts.map((x, index) => (
                                        <option key={index} value={x.loanNumber}>{x.loanNumber}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="dash_LA_TextHead">Two Wheeler Loan</div>
                        <ul className="dash_LA_IIcol">
                            <li className="dash_LA_IIcol">
                                <div className="dash_LA_Text">Loan Amount: <b>{Helper.INRCurrencyFormat(selectedAccountItem?.loanAmount, true)}</b></div>
                                <div className="dash_LA_Text">Rate of Interest: <b>{Helper.ToFixed(selectedAccountItem?.rateOfInterest, 2)}%</b></div>
                                <div className="dash_LA_Text">Tenure: <b>{selectedAccountItem?.tenure} Mts</b> <div className="dash_LA_NoteText">({selectedAccountItem?.numEmiPaid} EMI Paid)</div></div>
                                {Helper.IsNullValue(selectedAccountItem?.dateOfClosed) && (
                                    <div className="dash_LA_Text">Next EMI Due: <b>{Helper.INRCurrencyFormat(selectedAccountItem?.emi, true)}</b>
                                        <div className="dash_LA_NoteText">(Due on {Helper.ToDate(selectedAccountItem?.nextEmiDate, "YYYY-MM-DD")})</div></div>
                                )}
                                {!Helper.IsNullValue(selectedAccountItem?.dateOfClosed) && (
                                    <div className="dash_LA_Text">Next EMI Due: <b>0</b>
                                        <div className="dash_LA_NoteText">(Closed on {Helper.ToDate(selectedAccountItem?.dateOfClosed, "YYYY-MM-DD")})</div></div>
                                )}
                            </li>
                            <li className="dash_LA_IIcol">
                                <DoughnutChart chartText={accountSummary.percent} values={accountSummary.values} />
                            </li>
                        </ul>
                    </>
                ) : (
                    <div className="dashNoLoan">
                        <div className="topspace"></div>
                        <div className="dashNoLoan_txt">You do not have any active loans.</div>
                        <div className="dashNoLoan_txt">Select loan products to fulfill your financial needs</div>
                        <Link to="/apply"><input type="button" className="ddashNoLoan_button" value="Apply Now" /></Link>
                    </div>
                )}
            </div>
        </div>
    );
}

const RenderLoanEligibility = () => {

    const [monthlyIncome, setMonthlyIncome] = useState('');
    const [monthlyExpense, setMonthlyExpense] = useState('');
    const [interestRate, setInterestRate] = useState('');
    const [loanTenure, setLoanTenure] = useState('');
    const [totalPayment, setTotalPayment] = useState('');

    const ResetValues = () => {
        setMonthlyIncome('');
        setMonthlyExpense('');
        setInterestRate('');
        setLoanTenure('');
        setTotalPayment('');
    };

    const CalculateEligibility = (e) => {
        e.preventDefault();
        if (monthlyIncome && interestRate && loanTenure) {
            const rate = parseFloat(interestRate) / 1200;
            const time = parseFloat(loanTenure);
            const emiValue = Math.round((100000 / time) + (100000 * rate));
            const income = parseFloat(monthlyIncome);
            const expenditure = parseFloat(monthlyExpense);
            const principle = (income - expenditure) * 0.5;
            const totalEligibility = Math.round(principle / emiValue * 100000);
            setTotalPayment(totalEligibility);
        } else {
            ResetValues();
        }
    };

    return (
        <div className="dash_shadowBlk">
            <div className="dashCornerRed"></div>
            <div className="dashBlkHead">Loan Eligibility Calculator</div>
            <div className="dash_LE">
                <ul className="dash_LE_IIcol">
                    <li className="dash_LE_IIcol">
                        <div className="dash_LE_FieldHead">Monthly Income</div><div className="formMandatory">*</div>
                        <div className="dash_LE_TextField">
                            <input
                                autoComplete={Helper.TimeStamp()}
                                type="text"
                                id="monthlyIncome"
                                value={monthlyIncome}
                                onChange={(e) => setMonthlyIncome(e.target.value)}
                            />
                        </div>
                    </li>
                    <li className="dash_LE_IIcol">
                        <div className="dash_LE_FieldHead">Monthly Expense</div><div className="formMandatory">*</div>
                        <div className="dash_LE_TextField">
                            <input
                                autoComplete={Helper.TimeStamp()}
                                type="text"
                                id="monthlyExpense"
                                value={monthlyExpense}
                                onChange={(e) => setMonthlyExpense(e.target.value)}
                            />
                        </div>
                    </li>
                    <li className="dash_LE_IIcol">
                        <div className="dash_LE_FieldHead">Interest Rate</div><div className="formMandatory">*</div>
                        <div className="dash_LE_TextField">
                            <input
                                autoComplete={Helper.TimeStamp()}
                                type="text"
                                id="interestRate"
                                value={interestRate}
                                onChange={(e) => setInterestRate(e.target.value)}
                            />
                        </div>
                    </li>
                    <li className="dash_LE_IIcol">
                        <div className="dash_LE_FieldHead">Loan Tenure</div><div className="formMandatory">*</div>
                        <div className="dash_LE_TextField">
                            <input
                                autoComplete={Helper.TimeStamp()}
                                type="text"
                                id="loanTenure"
                                value={loanTenure}
                                onChange={(e) => setLoanTenure(e.target.value)}
                            />
                        </div>
                    </li>
                </ul>
                <ul className="dash_LE_btnResult_Blk">
                    <li className="dash_LE_btnResult_Blk">
                        <input type="button" className="dash_LE_button" value="Calculate" onClick={(e) => CalculateEligibility(e)} />
                    </li>
                    {!Helper.IsNullValue(totalPayment) && (
                        <li className="dash_LE_btnResult_Blk">
                            <div className="dash_LE_resultHead">Eligible Amount -</div>
                            <div className="dash_LE_result">{Helper.INRCurrencyFormat(totalPayment, true)}</div>
                        </li>
                    )}
                </ul>

            </div>
        </div>
    );
}

const RenderUpcomingPaymentChild = ({ item }) => {
    return (
        <>
            <li className="dash_UP_IIcol">
                <div className="dash_UP_Date">{Helper.ToDate(item.emiDate, 'YYYY-MM-DD')}</div>
                <div className="dash_UP_LoanType">{item.loanType}</div>
                <div className="dash_UP_LoanNo">Loan No: {item.loanNumber}</div>
            </li>
            <li className="dash_UP_IIcol">
                <div className="dash_UP_LoanAmt">{Helper.INRCurrencyFormat(item?.emi)}</div>
                {/* <input type="button" className="dash_UP_button" value="Pay Amount" /> */}
            </li>
        </>
    );

}

const RenderUpcomingPayments = (props) => {
    const { accounts } = props;

    const [payments, setPayments] = useState([]);

    useEffect(() => {

        if (accounts && accounts.length > 0) {
            let items = accounts.map((x) => x.upcomingList) || [];
            if (items && items.length > 0) {
                items = items.filter((z) => !Helper.IsNullValue(z.emi));
            }
            setPayments(items);
        }

    }, [accounts]);

    const height = accounts && accounts.length > 0 ? undefined : 210;

    return (
        <div className="dash_shadowBlk_bluebg" style={{ height }}>
            <div className="dashBlkHeadWhite">Upcoming Payments</div>
            {payments && payments.length > 0 ? (
                <>
                    <div className="dash_UP">
                        <ul className="dash_UP_IIcol">
                            {payments && payments.slice(0, 2).map((x, index) => (
                                <RenderUpcomingPaymentChild key={index} item={x} />
                            ))}
                        </ul>
                        <ul className="dash_UP_Bottom">
                            <li className="dash_UP_Bottom" style={{ width: "100%" }}><Link to="#">View All</Link></li>
                            {/* <li className="dash_UP_Bottom"><Link to="#">Set Repeat Transfers</Link></li> */}
                        </ul>
                    </div>
                </>
            ) : (
                <>
                    <div className="dashNoLoan">
                        <div className="topspace"></div>
                        <div className="dashNoLoan_txt" style={{ color: "#ffffff" }}>You do not have any active loans.</div>
                        <div className="dashNoLoan_txt" style={{ color: "#ffffff" }}>Select loan products to fulfill your financial needs</div>
                        <Link to="/apply"><input type="button" className="ddashNoLoan_button" value="Apply Now" /></Link>
                    </div>
                </>
            )}
        </div>
    );
}

const RenderMakePayments = (props) => {
    const { accounts } = props;
    const [showMaintenance, setShowMaintenance] = useState(false);

    const [selectedAccount, setSelectedAccount] = useState("");

    const OnSelectInputChanged = (e) => {
        let _value = e.target.value;
        setSelectedAccount(_value);
    }

    const OnProceedClicked = (e) => {
        e.preventDefault();
        setShowMaintenance(true);
        setTimeout(() => { setShowMaintenance(false); }, 5000);
    }

    useEffect(() => {
        setShowMaintenance(false);
    }, []);

    return (
        <div className="dash_shadowBlk_165">
            <div className="dash_MP">
                {accounts && accounts.length > 0 ? (
                    <>
                        <div className="dash_mp_blkHead">Make Payment:</div>
                        <div className="dash_mp_texthead">Select the type of payment towards -</div>
                        <div className="dash_mp_SelectLoan">
                            <select name="dpLoanPayAccount" id="dpLoanPayAccount"
                                onChange={(e) => OnSelectInputChanged(e)}
                                value={Helper.ToString(selectedAccount)} >
                                <option value="" disabled>{accounts.length} Loan No</option>
                                {
                                    accounts.map((x, index) => (
                                        <option key={index} value={x._id}>{x.loanNumber}</option>
                                    ))
                                }
                            </select>
                        </div>

                        <ul className="dash_mp_IIIcol">
                            <li className="dash_mp_IIIcol">
                                <ul className="dash_mp_IIcol">
                                    <li className="dash_mp_IIcol">
                                        <div className="dash_mp_radioLabel">
                                            <input type="radio" id="cb_address" name="mp" /><label htmlFor="cb_address"></label>
                                        </div>
                                    </li>
                                    <li className="dash_mp_IIcol">
                                        <div className="dash_mp_textBold">Part Pre-Payment</div>
                                        <div className="dash_mp_textNormal">Pay part of the principal outstanding amount</div>
                                    </li>
                                </ul>
                            </li>
                            <li className="dash_mp_IIIcol">
                                <ul className="dash_mp_IIcol">
                                    <li className="dash_mp_IIcol">
                                        <div className="dash_mp_radioLabel">
                                            <input type="radio" id="cb_address1" name="mp" /><label htmlFor="cb_address1"></label>
                                        </div>
                                    </li>
                                    <li className="dash_mp_IIcol">
                                        <div className="dash_mp_textBold">Advance EMI</div>
                                        <div className="dash_mp_textNormal">Make payment of 5 EMIs in advance</div>
                                    </li>
                                </ul>
                            </li>
                            <li className="dash_mp_IIIcol">
                                <ul className="dash_mp_IIcol">
                                    <li className="dash_mp_IIcol">
                                        <div className="dash_mp_radioLabel">
                                            <input type="radio" id="cb_address2" name="mp" /><label htmlFor="cb_address2"></label>
                                        </div>
                                    </li>
                                    <li className="dash_mp_IIcol">
                                        <div className="dash_mp_textBold">Full Payment</div>
                                        <div className="dash_mp_textNormal">Pay remaining principal outstanding amount </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <input type="button" className="dash_mp_button" value="Proceed" onClick={(e) => OnProceedClicked(e)} />
                        {showMaintenance && <div className="dash_mp_mnt">Under Maintenance</div>}
                    </>
                ) : (
                    <>
                        <div className="dash_mp_blkHead">Make Payment</div>
                        <div className="dashNoLoan">
                            <div className="topspace"></div>
                            <div className="dashNoLoan_txt">You do not have any active loans.</div>
                            <div className="dashNoLoan_txt">Select loan products to fulfill your financial needs</div>
                            <Link to="/apply"><input type="button" className="ddashNoLoan_button" value="Apply Now" /></Link>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

const RenderQuickLinks = () => {
    return (
        <div className="dash_shadowBlk_165">
            <div className="dash_QL">
                <div className="dashBlkHead_bot13">Quick Links</div>
                <ul className="dash_QL_VIcol">
                    <li className="dash_QL_VIcol">
                        <Link to="/apply">
                            <div className="dash_QL_ApplyIcon"></div>
                            <div className="dash_QL_txt">Apply For Loan</div>
                        </Link>
                    </li>
                    <li className="dash_QL_VIcol">
                        <Link to="/cl">
                            <div className="dash_QL_ViewStatement"></div>
                            <div className="dash_QL_txt">View Statement</div>
                        </Link>
                    </li>
                    <li className="dash_QL_VIcol">
                        <Link to="#">
                            <div className="dash_QL_Transfer"></div>
                            <div className="dash_QL_txt">Create UPI Mandate</div>
                        </Link>
                    </li>
                    <li className="dash_QL_VIcol">
                        <Link to="#">
                            <div className="dash_QL_Eligibility"></div>
                            <div className="dash_QL_txt">Eligibility Calculator</div>
                        </Link>
                    </li>
                    <li className="dash_QL_VIcol">
                        <Link to="/emi">
                            <div className="dash_QL_Calculator"></div>
                            <div className="dash_QL_txt">Installment Calculator</div>
                        </Link>
                    </li>
                    <li className="dash_QL_VIcol">
                        <Link to="#">
                            <div className="dash_QL_PayLoan"></div>
                            <div className="dash_QL_txt">Pay Loan Installment</div>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}

const RenderLoanAccountSummary = (props) => {
    const { accounts, onDialogType } = props;
    const NavigateTo = useNavigate();

    const OnApplyNewLoanClicked = (e) => {
        e.preventDefault();
        onDialogType('EASYAPPLY');
    }

    const OnLoanViewClicked = (e, loanNumber) => {
        e.preventDefault();
        Session.Store('loanNumber', loanNumber);
        NavigateTo("/cld");
    }

    return (
        <div className="dash_shadowBlk_220">
            <div className="dash_head_right">
                <input type="button" className="dash_headRight_button" value="Quick Apply" onClick={(e) => OnApplyNewLoanClicked(e)} />
            </div>
            <div className="dashBlkHead_bot13">Loan Accounts Summary</div>

            <div className="dash_LAS">
                <div className="dash_LAS_head">
                    <table>
                        <tbody>
                            <tr>
                                <td>Loan Type</td>
                                <td>Account No</td>
                                <td>Rate</td>
                                <td>Maturity Date</td>
                                <td>Outstanding</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {accounts && accounts.length > 0 ? (
                    <>
                        <div className="dash_LAS_Table">
                            <table>
                                <tbody>
                                    {accounts && accounts.slice(0, 3).map((x, index) => (
                                        <tr key={index}>
                                            <td>{x.loanType}</td>
                                            <td><Link to="#" onClick={(e) => OnLoanViewClicked(e, x.loanNumber)}>{x.loanNumber}</Link></td>
                                            <td>{Helper.ToFixed(x.rateOfInterest, 2)}%</td>
                                            <td>{Helper.ToDate(new Date(x.maturityDate), "DD-MMM-YYYY")}</td>
                                            <td>{Helper.INRCurrencyFormat(x.balanceAmount, true)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="dash_LAS_bottom"><Link to="/cl">View All</Link></div>
                    </>

                ) : (
                    <>
                        <div className="dashNoLoan">
                            <div className="topspace"></div>
                            <div className="dashNoLoan_txt">You do not have any active loans.</div>
                            <div className="dashNoLoan_txt">Click on QuickApply</div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

const RenderMiscellaneous = ({ onDialogType }) => {

    const NavigateTo = useNavigate();

    const OnApplyNewLoanClicked = (e) => {
        e.preventDefault();
        NavigateTo("/apply");
    }

    const OnCreditScoreClicked = async (e) => {
        e.preventDefault();
        onDialogType('CREDTISCORE');
    };

    return (
        <ul className="dashIIcol">
            <li className="dashIIcol">
                <div className="dash_NLCL">
                    <div className="dash_NL_Blk">
                        <div className="dash_shadowBlk_110">
                            <div className="dashBlkHead_bot5">New Loan</div>
                            <ul className="dash_NLCL_IIcol">
                                <li className="dash_NLCL_IIcol">
                                    <div className="dash_NLCL_txt">
                                        Select loan products to fulfill your financial needs
                                    </div>
                                    <input type="button" className="dash_NLCL_button" value="Apply Now" onClick={(e) => OnApplyNewLoanClicked(e)} />
                                </li>
                                <li className="dash_NLCL_IIcol">
                                    <div className="dash_NL_Icon"></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="dash_CL_Blk">
                        <div className="dash_shadowBlk_110">
                            <div className="dashBlkHead_bot5">Closed Loan</div>
                            <ul className="dash_NLCL_IIcol">
                                <li className="dash_NLCL_IIcol">
                                    <div className="dash_NLCL_txt">
                                        View all your previous closed loans from here
                                    </div>
                                    <input type="button" className="dash_NLCL_button" value="View All" onClick={(e) => {
                                        e.preventDefault();
                                        NavigateTo('/ccl');
                                    }} />
                                </li>
                                <li className="dash_NLCL_IIcol">
                                    <div className="dash_CL_Icon"></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>
            <li className="dashIIcol">
                <div className="dash_shadowBlk_220">
                    <div className="dash_headNoteTxt">Get Your</div>
                    <div className="dashBlkHead_C">Free Credit Report</div>
                    <div className="dash_CS">
                        <div className="dash_CS_icon"></div>
                        <div className="dash_CS_text">A CIBIL Score above 750 can help you get better terms on loans.</div>
                        <div className="dash_CS_text">Check your score for free now.</div>
                        <div className="dash_CS_buttonBlk">
                            <input type="button" className="dash_CS_button" value="Check Score" onClick={(e) => OnCreditScoreClicked(e)} />
                        </div>

                    </div>
                </div>
            </li>
        </ul>
    );
}

const Component = () => {

    const [initlized, setInitlized] = React.useState(false);
    const [loanAccounts, setLoanAccounts] = React.useState([]);
    const [counts, setCounts] = React.useState({});
    const [dialogName, setDialogName] = React.useState(null);
    const [dialogShow, setDialogShow] = React.useState(false);

    const OnDialogType = (e) => {
        setDialogName(e);
        setDialogShow(true);
    }

    const OnDialogClose = (e) => {
        setDialogName(null);
        setDialogShow(false);
    }

    const FetchResults = async () => {
        global.Busy(true);
        let rslt = await FinstaApi.GetLoanPortfolio();
        global.Busy(false);
        if (rslt.status === 100) {
            setLoanAccounts(rslt.data);
            setCounts(rslt.counts);
        }
    };

    if (initlized) {
        setInitlized(false);
        setLoanAccounts([]);
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    return (
        <>
            <Container>
                <div id="container">
                    <div id="content">
                        <div className="dashboard">
                            <div className="widthfix_10px">
                                <ul className="dashIVcol">
                                    <li className="dashIVcol">
                                        <RenderLoanPortfolio counts={counts} accounts={loanAccounts} />
                                    </li>
                                    <li className="dashIVcol">
                                        <RenderLoanAccounts accounts={loanAccounts} />
                                    </li>
                                    <li className="dashIVcol">
                                        <RenderLoanEligibility />
                                    </li>
                                    <li className="dashIVcol">
                                        <RenderUpcomingPayments accounts={loanAccounts} />
                                    </li>
                                </ul>
                                <ul className="dashIIcol">
                                    <li className="dashIIcol">
                                        <RenderMakePayments accounts={loanAccounts} />
                                    </li>
                                    <li className="dashIIcol">
                                        <RenderQuickLinks />
                                    </li>
                                    <li className="dashIIcol">
                                        <RenderLoanAccountSummary onDialogType={OnDialogType} accounts={loanAccounts} />
                                    </li>
                                    <li className="dashIIcol">
                                        <RenderMiscellaneous onDialogType={OnDialogType} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <DialogContainer show={dialogShow} type={dialogName} OnCloseClick={OnDialogClose} />
            </Container>
        </>
    )
}

export default Component;