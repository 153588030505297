import { Link, Session, useNavigate, useState } from "common";

const Component = (props) => {

    const { show } = props;
    const [showDialog, setShowDialog] = useState(show);
    const Navigate = useNavigate();
    let isClosed = false;

    const OnCloseClicked = (e) => {
        if (e) e.preventDefault();
        isClosed = true;
        setShowDialog(false);
    }

    const OnLoanTypeSelected = (e, type, name) => {
        e.preventDefault();
        if (!isClosed) {
            Session.Store('loanTypeId', type);
            Session.Store('loanTypeName', name);
            Navigate("/apply");
        }
    }

    if (!showDialog) return null;

    return (
        <div id="adBanner_pop" className="pop_disable_bg" style={{ display: "block" }}>
            <div className="adBanner_pop">
                <Link to="#" onClick={(e) => OnLoanTypeSelected(e, 1, "Two Wheeler Loan")}>
                    <div className="adBanner_ContentArea">
                        <div className="adBanner_CloseButton_Layout">
                            <input type="button" className="adBannerClose_Button" value="X" onClick={(e) => OnCloseClicked(e)} />
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default Component;