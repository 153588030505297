import React from "react";
import DatePicker from "react-datepicker";
import { Helper } from "helper";

const Component = (props) => {

    const { index, path, name, value, valuetype, age, errors, dateFormat,
        onBlurError, onInputClicked, onInputChildChanged, required, requiredMessage, placeholder } = props;

    const [startDate, setStartDate] = React.useState();
    const [maxDate, setMaxDate] = React.useState();
    const [controlName, setControlName] = React.useState("dtTextinput");

    const datepickerRef = React.useRef(null);

    const OnInputClicked = (e) => {
        e.preventDefault();
        if (onInputClicked) {
            onInputClicked(e.target.name);
        }
    }

    const OnBlurChanged = async (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        if (valuetype && valuetype === "DOB") {
            if (required && Helper.IsNullValue(value)) {
                RaiseOnBlurError(name, requiredMessage); return;
            } else {
                RaiseOnBlurError(name, null, true);
            }
        }
    };

    const RaiseOnBlurError = (name, val, clear, data) => {
        global.Busy(false);
        if (onBlurError) {
            onBlurError(name, val, clear, data);
        }
    }

    const OnTextInputChanged = (value) => {
        setStartDate(value);
        if (onInputChildChanged) {
            let _name = GetKeyName();
            let _formatVal = Helper.ToDateFormat(value, dateFormat?.toUpperCase());
            onInputChildChanged(_name, _formatVal, index);
        }
    };

    const GetKeyName = () => {
        let _name = `dtTextinput`;
        if (name) {
            _name = `${name.replace(/\./g, '_')}`;
        } if (path) {
            _name = `${path.replace(/\./g, '_')}`;
        }
        return _name;
    };

    React.useEffect(() => {
        setStartDate(null);
        if (!Helper.IsNullValue(value)) {
            setStartDate(new Date(Helper.ToESTDate(value, "MM/DD/YYYY")));
        }
    }, [value]);

    React.useEffect(() => {
        const fn = () => {
            let _name = `dtTextinput`;
            if (name) {
                _name = `${name.replace(/\./g, '_')}`;
            } if (path) {
                _name = `${path.replace(/\./g, '_')}`;
            }
            setControlName(_name);
        };
        fn();
    }, [name, path]);

    React.useEffect(() => {
        let element = document.getElementById(controlName);
        element && element.addEventListener('keydown', (evt) => {
            if (evt.key === 'Tab') {
                if (datepickerRef.current.isCalendarOpen) {
                    datepickerRef.current.setOpen(false);
                }
            }
        });
    }, [controlName]);

    React.useEffect(() => {
        setMaxDate(null)
        if (!Helper.IsNullValue(valuetype)) {
            if (valuetype === 'DOB') {
                setMaxDate(Helper.GetDOBDate(age));
            }
        }
    }, [valuetype, age]);

    return (
        <>
            <div className={Helper.GetErrorBackColor(errors, controlName) ? "datepickerError" : undefined} >
                <DatePicker
                    ref={datepickerRef}
                    disabledKeyboardNavigation
                    dateFormat={dateFormat}
                    onBlur={(e) => OnBlurChanged(e)}
                    onFocus={(e) => OnInputClicked(e)}
                    id={controlName}
                    name={controlName}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    title="Date Of Birth"
                    placeholderText={placeholder}
                    maxDate={maxDate}
                    onChange={(e) => OnTextInputChanged(e)}
                    selected={startDate} />
            </div>
        </>
    );
}

export default Component;
