import API from "./api";

const GetLoanTypes = async () => {
    try {
        const res = await API.get(`lookups/loantypes`);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetVehicleManufacturers = async () => {
    try {
        const res = await API.get(`lookups/vehiclemanufacturers`);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetVehicleModels = async (input) => {
    try {
        const res = await API.get(`lookups/vehiclemodels/${input}`);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetVehicleVariants = async (input) => {
    try {
        const res = await API.get(`lookups/vehiclevariants/${input}`);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetVehicleCityDealers = async (id, state) => {
    try {
        const res = await API.get(`lookups/vehicledealers/${id}/${state}`);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetCities = async () => {
    try {
        const res = await API.get(`lookups/cities`);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetStates = async () => {
    try {
        const res = await API.get(`lookups/states`);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetLookUpById = async (id) => {
    try {
        const res = await API.get(`lookups/${id}`);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetBranches = async () => {
    try {
        const res = await API.get(`lookups/branches`);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};


export {
    GetLoanTypes,
    GetVehicleManufacturers, GetVehicleModels, GetVehicleVariants, GetVehicleCityDealers,
    GetCities, GetStates, GetLookUpById, GetBranches
};
