import API from "./api";
import session from "../helper/session";

const GetLoanPortfolio = async () => {
    try {
        const token = await session.GetHeader();
        const res = await API.get(`finsta/portfolio`, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetLoanAccountSummary = async (input) => {
    try {
        input = input || {};
        const token = await session.GetHeader();
        const res = await API.get(`finsta/summary/${input}`, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetAccountDetails = async (input) => {
    try {
        input = input || {};
        const token = await session.GetHeader();
        const res = await API.get(`/finsta/details/${input}`, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

export { GetLoanPortfolio, GetLoanAccountSummary, GetAccountDetails };
