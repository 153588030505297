import { React, Slider, Session, Link, useNavigate } from "common";
import Mp4Video from "../video/083041670.mp4";
import WebmVideo from "../video/083041670.webm";
import QuickApply from "../../shared/components/quickapply";

import DialogContainer from "screens/shared/popups/dialog";

const SliderComponent = ({ children }) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
  };

  return <Slider {...settings}>{children} </Slider>
}

const Component = () => {

  const NavigateTo = useNavigate();

  const OnApplyNowClicked = (e, val) => {
    e.preventDefault();
    Session.Store('loanTypeId', val);
    NavigateTo("/apply");
  };

  return (
    <>
      <div id="homeVideo">
        <div className="videoContainer">
          <video className="video1-bg" autoPlay={true} loop muted style={{ width: '100%' }}>
            <source src={Mp4Video} />
            <source src={WebmVideo} />
            Video not supported.
          </video>
        </div>
        <div className="fallback_smallscreen"></div>

        <div className="videoContent_blk">
          <div className="widthfix_corousel">
            <SliderComponent>
              {/* First Slide - Generic */}
              <div className="widthfix_corousel_content">
                <ul className="homeText_Quote_blk">
                  <li className="homeText">
                    <div className="homeMainTxtBlock">
                      <h1>
                        <div className="mainTxt_line">We are making the loan process</div>
                        <div className="mainTxt_line">Simpler, Faster and most importantly </div>
                        <div className="mainTxt_line">Accessible to all INDIAN's</div>
                      </h1>
                    </div>
                    <div className="secondaryTxt">
                      Apply for your Gold Loan, Two Wheeler Loan, Business Loan or Loan Against Property with Moham Capital
                    </div>
                    <div className="homeButLabel" >
                      <Link to="/preapproval"><input
                        type="submit"
                        className="but_preQuaify"
                        value="Get Pre-Approved"
                        title="Get Pre-Approved in 15 Sec"
                      /></Link>
                      <Link to="#" onClick={(e) => OnApplyNowClicked(e, 0)}><input
                        type="submit"
                        className="but_quickApply"
                        value="Apply Now" />
                      </Link>
                    </div>
                  </li>

                  <li className="requestQuote">
                    <QuickApply />
                  </li>

                </ul>
              </div>
              {/* Second Slide - Bike Loan */}
              <div className="widthfix_corousel_content">
                <ul className="homeText_Quote_blk">
                  <li className="homeTextFullLength">
                    <div className="homeMainTxtBlock">
                      <h1>
                        {/* <div className="mainTxt_line">Simpler &amp; Faster Loans.</div> */}
                        <div className="mainTxt_line">Found your dream bike!</div>
                        <div className="mainTxt_line">Take the first step towards freedom with our</div>
                        <div className="mainTxt_line">two-wheeler loan and turn it into reality</div>
                        {/* <div className="mainTxt_line">A Two Wheeler Loan is just what you need </div>
                        <div className="mainTxt_line">to bring it home</div> */}
                      </h1>
                    </div>
                    <div className="secondaryTxt">
                      Apply for a two-wheeler loan online by filling out a simple application form. Choose from a variety of tenures and down-payment options that is right for you.
                    </div>
                    <div className="homeButLabel" >
                      <Link to="/bikeloan"><input
                        type="submit"
                        className="but_preQuaify"
                        value="Know More"
                        title="Get Loan in 15 Min"
                      /></Link>

                      <Link to="#" onClick={(e) => OnApplyNowClicked(e, 1)}><input
                        type="submit"
                        className="but_quickApply"
                        value="Apply Now" />
                      </Link>
                    </div>
                  </li>
                  <li className="requestQuote" style={{ display: "none" }}>
                    <div className="requestHead">Want a Bike Loan?</div>
                    <div className="requestSub">select your brand and apply</div>

                    <select name="filterType" defaultValue={'DEFAULT'} required >
                      <option value="DEFAULT" disabled>Select Brand</option>
                      <option value="#">Royal Enfield</option>
                      <option value="#">Hero</option>
                      <option value="#">Yamaha</option>
                      <option value="#">Suzuki</option>
                    </select>

                    <select name="filterType" defaultValue={'DEFAULT'} required >
                      <option value="DEFAULT" disabled>Select Model</option>
                      <option value="#">Royal Enfield</option>
                      <option value="#">Hero</option>
                      <option value="#">Yamaha</option>
                      <option value="#">Suzuki</option>
                    </select>

                    <select name="filterType" defaultValue={'DEFAULT'} required >
                      <option value="DEFAULT" disabled>Select City</option>
                      <option value="#">Bangalore</option>
                      <option value="#">Chennai</option>
                    </select>

                    <input type="text" placeholder="Mobile" />

                    <Link to="/apply"><input
                      type="button"
                      value="Apply Now"
                      className="requestButton"
                    /></Link>
                  </li>
                </ul>
              </div>
              {/* Third Slide - Gold Loan */}
              <div className="widthfix_corousel_content">
                <ul className="homeText_Quote_blk">
                  <li className="homeTextFullLength">
                    <div className="homeMainTxtBlock">
                      <h1>
                        <div className="mainTxt_line">Why go for any other Loan</div>
                        <div className="mainTxt_line">when you have the power of Gold with you.</div>
                        <div className="mainTxt_line">Get a Gold Loan, in minutes!</div>
                      </h1>
                    </div>
                    <div className="secondaryTxt">
                      Apply for your Gold Loan by pledging your Gold Ornaments and Jewellery as a collateral deposit against the money you want
                    </div>
                    <div className="homeButLabel">
                      <Link to="/goldloan">
                        <input
                          type="submit"
                          className="but_preQuaify"
                          value="Know More"
                          title="Gold Loan in 15 Min"
                        />
                      </Link>

                      <Link to="#" onClick={(e) => OnApplyNowClicked(e, 2)}><input
                        type="submit"
                        className="but_quickApply"
                        value="Apply Now" />
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>

              {/* Fourth Slide - Loan Against Property */}
              <div className="widthfix_corousel_content">
                <ul className="homeText_Quote_blk">
                  <li className="homeTextFullLength">
                    <div className="homeMainTxtBlock">
                      <h1>
                        <div className="mainTxt_line">Unlock the value of your Property</div>
                        <div className="mainTxt_line">with a Loan Against Property and use the funds</div>
                        <div className="mainTxt_line">for other large expenses</div>
                      </h1>
                    </div>
                    <div className="secondaryTxt">
                      A loan against property is a type of secured loan taken against the value of a property. These loans offers lower interest rates compared to unsecured loans.
                    </div>
                    <div className="homeButLabel">
                      <Link to="/propertyloan">
                        <input
                          type="submit"
                          className="but_preQuaify"
                          value="Know More"
                          title="Property Loan in 15 Min"
                        />
                      </Link>

                      <Link to="#" onClick={(e) => OnApplyNowClicked(e, 3)}><input
                        type="submit"
                        className="but_quickApply"
                        value="Apply Now" />
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>

              {/* Fifth Slide - Business Loan */}
              <div className="widthfix_corousel_content">
                <ul className="homeText_Quote_blk">
                  <li className="homeTextFullLength">
                    <div className="homeMainTxtBlock">
                      <h1>
                        <div className="mainTxt_line">Unlock your business's potential with</div>
                        <div className="mainTxt_line">Moham Capital Business Loan. We make business </div>
                        <div className="mainTxt_line">loans simple and straight forward.</div>
                      </h1>
                    </div>
                    <div className="secondaryTxt">
                      A business loan is a type of loan designed for businesses to meet financial obligations. Business loans can be used for expanding the business, or managing cash flow.
                    </div>
                    <div className="homeButLabel">
                      <Link to="/businessloan">
                        <input
                          type="submit"
                          className="but_preQuaify"
                          value="Know More"
                          title="Business Loan in 15 Min"
                        />
                      </Link>

                      <Link to="#" onClick={(e) => OnApplyNowClicked(e, 5)}><input
                        type="submit"
                        className="but_quickApply"
                        value="Apply Now" />
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </SliderComponent>
          </div>
        </div >

        <div className="QuickApplyHome">
          <div className="QuickApplyHomePos">
            <QuickApply mobileview={true} />
          </div>
        </div>

      </div>
      <DialogContainer show={true} type={'NONE'} />
    </>
  );
};

export default Component;
