import { React, useEffect, useState, TextInput, Link, Helper } from "common";
import { CommonApi } from "services";


const Component = (props) => {

    const { show, onClose } = props;

    const [row, setRow] = useState({ creditscore: {} });
    const [errors, setErrors] = useState([]);
    const [error, setError] = useState(null);
    const [state, setState] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [disclaimer, setDisclaimer] = useState(false);
    const [success, setSuccess] = useState(null);

    const rootpath = "creditscore";

    const OnBlurError = (name, val, clear) => {
        setSuccess(null);
        let _errors = [];
        if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
        if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
        setErrors(_errors);
        setError(val);
    };

    const OnInputChanged = async (path, value) => {
        let newRow = Helper.ModifyJsonArrayObject4(row, path, value);
        setRow(newRow);
        setState(!state);
    }

    const OnInputClicked = (name) => {
        setSuccess(null);
        let _err = null;
        if (errors && errors.length > 0) {
            let _findIndex = errors.findIndex((x) => x.field === name);
            if (_findIndex > -1) {
                _err = errors[_findIndex].value;
            }
        }
        setError(_err);
    };

    const OnRecordValidate = () => {
        setError("");
        setErrors([]);
        const { errors, valid } = Helper.IsFormValid('creditScore_form');

        if (!valid) {
            setErrors(errors);
            setError("Fill all required details");
            return false;
        }
        return true;
    };

    const OnApplyClicked = async (e) => {
        e.preventDefault();
        if (!OnRecordValidate()) return;

        let data = { ...row.creditscore, verifiedOn: new Date() };

        setErrors([]);
        setError(null);
        setSuccess(null);

        let keyId = Helper.CleanPhoneNumber(row.creditscore.phone.toString());

        global.Busy(true);
        let rslt = await CommonApi.ValidateOtp("mobile", keyId, parseInt(row.creditscore.otpNumber), 56);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            global.Busy(false);
            return;
        }

        rslt = await CommonApi.CreditScore(data);
        global.Busy(false);
        if (rslt.status !== 100) {
            setErrors(rslt.errors);
            setError(rslt.statusText);
            return;
        } else {
            setSuccess("Application Submitted Succesfully");
            setRow({ creditscore: {} });
            setOtpSent(false);
        }
    }

    const OnClosedDialog = (e) => {
        e.preventDefault();
        setErrors([]);
        setDisclaimer(false);
        setError(null);
        setSuccess(null);
        setRow({ creditscore: {} });
        //window.hide("creditScore_pop");
        onClose();
    }

    const OnDisclaimerClicked = (e) => {
        e.preventDefault();
        setDisclaimer(!disclaimer);
    }

    const OnGetOtpClicked = async (e) => {
        e.preventDefault();
        setError("");
        setErrors([]);
        setOtpSent(false);
        let { errors, valid } = Helper.IsFormValid('creditScore_form');
        errors = errors.filter(x => x.field !== "creditscore_otpNumber");
        valid = errors.length === 0;
        if (!valid) {
            setErrors(errors);
            setError("Fill all required details");
            return false;
        }

        let keyId = Helper.CleanPhoneNumber(row.creditscore.phone.toString());
        global.Busy(true);
        let rslt = await CommonApi.GetOtp('mobile', keyId);
        global.Busy(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        }
        setOtpSent(true);
    }

    useEffect(() => {
        setErrors([]);
        setDisclaimer(false);
        setError(null);
        setSuccess(null);
        setRow({ creditscore: {} });
    }, []);


    if (!show) return null;

    return (
        <div id="creditScore_pop" className="pop_disable_bg" style={{ display: "block" }}>
            <div className="creditScore_pop">
                <div className="creditScore_ContentArea">
                    <div className="creditScore_Head">Check your Credit Score</div>
                    <ul id="creditScore_form" className="pop_creditScoreForm">
                        <li className="pop_creditScoreForm">
                            <div className="pop_creditScoreHead">Name<div className="formMandatory">*</div></div>
                            <div className="pop_creditScoreTxtField">
                                <TextInput
                                    errors={errors}
                                    path={`${rootpath}.name`}
                                    value={row?.creditscore?.name}
                                    onInputChildChanged={OnInputChanged}
                                    onBlurError={OnBlurError}
                                    onInputClicked={OnInputClicked}
                                    requiredMessage={'Name is required'}
                                    dataRequired={true}
                                    required={true}
                                />
                            </div>
                        </li>
                        <li className="pop_creditScoreForm">
                            <div className="pop_creditScoreHead">PAN</div>
                            <div className="pop_creditScoreTxtField">
                                <TextInput
                                    errors={errors}
                                    path={`${rootpath}.pan`}
                                    valuetype={"PAN"}
                                    value={row?.creditscore?.pan}
                                    onInputChildChanged={OnInputChanged}
                                    onBlurError={OnBlurError}
                                    onInputClicked={OnInputClicked}
                                    requiredMessage={'Pan Number is required'}
                                    validationMessage={'Enter valid Pan'}
                                    dataRequired={false}
                                    validate={true}
                                    required={false}
                                    uppercase={true}
                                />
                            </div>
                        </li>
                        <li className="pop_creditScoreForm">
                            <div className="pop_creditScoreHead">Aadhaar<div className="formMandatory">*</div></div>
                            <div className="pop_creditScoreTxtField">
                                <TextInput
                                    maxLength={12}
                                    errors={errors}
                                    path={`${rootpath}.aadhaar`}
                                    valuetype={"AADHAR"}
                                    value={row?.creditscore?.aadhaar}
                                    onInputChildChanged={OnInputChanged}
                                    onBlurError={OnBlurError}
                                    onInputClicked={OnInputClicked}
                                    requiredMessage={'Aadhaar Number is required'}
                                    validationMessage={'Enter valid Aadhaar'}
                                    dataRequired={true}
                                    validate={true}
                                    required={true}
                                />
                            </div>
                        </li>
                        <li className="pop_creditScoreForm">
                            <div className="pop_creditScoreHead">Email<div className="formMandatory">*</div></div>
                            <div className="pop_creditScoreTxtField">
                                <TextInput
                                    errors={errors}
                                    path={`${rootpath}.email`}
                                    valuetype={"EMAIL"}
                                    value={row?.creditscore?.email}
                                    onInputChildChanged={OnInputChanged}
                                    onBlurError={OnBlurError}
                                    onInputClicked={OnInputClicked}
                                    requiredMessage={'Email Address is required'}
                                    validationMessage={'Enter valid email-id'}
                                    dataRequired={true}
                                    validate={true}
                                    required={true}
                                />
                            </div>
                        </li>
                        <li className="pop_creditScoreForm">
                            <div className="pop_creditScoreHead">Phone<div className="formMandatory">*</div></div>
                            <div className="pop_creditScoreTxtField">
                                <TextInput
                                    errors={errors}
                                    path={`${rootpath}.phone`}
                                    value={row?.creditscore?.phone}
                                    onInputChildChanged={OnInputChanged}
                                    onBlurError={OnBlurError}
                                    onInputClicked={OnInputClicked}
                                    requiredMessage={'Phone is required'}
                                    dataRequired={true}
                                    required={true}
                                    valuetype={"PHONE"}
                                    validationMessage={'Enter valid Phone Number'}
                                    validate={true}
                                />
                            </div>
                        </li>
                        <li className="pop_creditScoreForm">
                            <div className="pop_creditScoreHead">Contacted Sales Person</div>
                            <div className="pop_creditScoreTxtField">
                                <TextInput
                                    errors={errors}
                                    path={`${rootpath}.salesperson`}
                                    value={row?.creditscore?.salesperson}
                                    onInputChildChanged={OnInputChanged}
                                    onBlurError={OnBlurError}
                                    onInputClicked={OnInputClicked}
                                    requiredMessage={'Contacted Sales Person is required'}
                                />
                            </div>
                        </li>
                        <li className="pop_creditScoreForm">
                            <div className="pop_creditScoreHead">OTP Number<div className="formMandatory">*</div></div>
                            <div className="pop_creditScoreTxtField">

                                <TextInput
                                    errors={errors}
                                    path={`${rootpath}.otpNumber`}
                                    value={row?.creditscore?.otpNumber}
                                    onInputChildChanged={OnInputChanged}
                                    onBlurError={OnBlurError}
                                    onInputClicked={OnInputClicked}
                                    requiredMessage={'OTP Number is required'}
                                    dataRequired={true}
                                    required={true}
                                    valuetype={"NUMBER"}
                                    validationMessage={'Enter valid OTP Number'}
                                    validate={true}
                                />

                            </div>
                        </li>
                        <li className="pop_creditScoreForm">
                            <div className="popCreditScore_Submit_Button_Layout">
                                <input type="button" className="popCreditScore_Submit_Button" value="Get OTP" onClick={OnGetOtpClicked} />
                            </div>
                        </li>
                    </ul>

                    <div className="popCreditScore_Submit_Button_Layout">
                        {otpSent && (<input type="button" className="popCreditScore_Submit_Button" value="Submit" onClick={(e) => OnApplyClicked(e)} />)}
                        <input type="button" className="popCreditScore_Submit_Button" value="Close" onClick={(e) => OnClosedDialog(e)} />
                    </div>

                    <div className="disclaimerAlert">
                        <div className="disclaimerLinkCenter">
                            By clicking on Submit, you agree to our <Link to="#" onClick={(e) => OnDisclaimerClicked(e)} >Disclaimer</Link>
                        </div>
                        {disclaimer && (
                            <div className="disclaimerTextCenter">By applying, you are providing “written instructions” to Moham Capital Pvt Ltd. under the Fair Credit Reporting Act authorizing Moham Capital Pvt Ltd.. to obtain information from your personal credit profile or other information from Credit Bureau. You authorize Moham Capital Pvt Ltd. to obtain such information solely to conduct a pre-qualification for credit.</div>
                        )}
                    </div>
                    {error && <div className="errormessage_center">{error}</div>}
                    {success && <div className="successmessage_center">You will receive an email soon</div>}
                </div>
            </div>
        </div>
    );
};

export default Component;