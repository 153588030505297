import { React, Helper } from "common";
import { GetSignedUrl } from "services/documentApi";

const maxsize = 106000000;

const Component = (props) => {
    const fileRef = React.useRef();
    const [fileItem, setFileItem] = React.useState(null);
    const [ferror, setFError] = React.useState(null);

    const { id, name, accept, readOnly, onInputChanged } = props;

    const type = "file";
    const tReadOnly = readOnly || false;
    const inputProps = { id, name, accept, autoComplete: Helper.TimeStamp(), type, disabled: tReadOnly };

    const OnInputChanged = (e) => {
        const { name, files } = e.target;
        setFError(null);
        setFileItem(null);
        if (files.length > 0) {
            let _file = files[0];
            let _ext = _file.name.split(".").pop().toUpperCase();
            let _index = accept.findIndex((x) => x.toUpperCase() === `.${_ext}`);
            if (_index === -1) {
                fileRef.current.value = null;
                setFError(`Supported Format: ${accept.join(",")}`);
                return;
            }
            else if (_file.size > maxsize) {
                fileRef.current.value = null;
                setFError(`File size allowed: 100mb`);
                return;
            } else {
                setFileItem(_file);
            }
        }
    };

    const OnFileUpload = async (e) => {
        e.preventDefault();
        if (!Helper.IsNullValue(fileItem)) {
            let _file = fileItem;
            let _ext = _file.name.split(".").pop().toUpperCase();
            let _fileName = `${new Date().valueOf()}.${_ext}`;
            let tmp = { fileName: `proofs/${_fileName}` };
            global.Busy(true);
            let rslt = await GetSignedUrl(tmp);
            global.Busy(false);
            if (rslt.status === 100) {
                let data = rslt.data;
                await UploadToBucket(data.url, _file, data.ContentType);
            } else {
                setFError("Failed uploading file");
            }
        }
    }

    const UploadToBucket = async (url, data, type) => {
        global.Busy(true);
        try {
            const res = await fetch(url, {
                method: "PUT",
                redirect: 'follow',
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": type,
                },
                body: data
            });

            global.Busy(false);
            if (res.ok) {
                const _url = res.url;
                let pathArray = _url.split("?")[0].split(".com");
                let urlFile = pathArray[1];
                if (urlFile.startsWith("/")) urlFile = urlFile.substring(1);
                onInputChanged && onInputChanged({ name, value: urlFile });
                fileRef.current.value = '';
                setFileItem(null);
                //setFError("Successfuly uploaded the file");
            } else {
                console.log(res.statusText);
                setFError("Failed uploading file");
            }

        } catch (err) {
            global.Busy(false);
            console.log(err);
            setFError("Failed uploading file");
        }
    };

    return (

        <li className="uploadDocHeadBlk">
            <div className="uploadHeading">
                Document Upload
            </div>
            <div className="fileUpload">
                <div className="uploadBox">
                    <input {...inputProps}
                        ref={fileRef}
                        onChange={OnInputChanged}
                        onClick={(e) => {
                            e.target.value = null;
                        }}
                    />
                </div>
                <div className="buttonUploadLayout">
                    <input type="submit" className="buttonUpload" value="Upload" onClick={(e) => OnFileUpload(e)} />
                </div>
                {ferror && <div className="submitError">{ferror}</div>}
            </div>
        </li>
    );
};

export default Component;