import { React, Container, Link, useTimerSession } from "common";

const Component = () => {

    const [token] = useTimerSession('token');
    const contentId = token ? 'content' : 'content_fullHeader';

    return (
        <>
            <Container>
                <div id="container" >
                    <div id={contentId}>
                        {!token && (
                            <div id="heroImg_about">
                                <div className="inPgBcShadeBlk">
                                    <div className="inPgBcCrossBlk">
                                        <div className="widthfix_10px">
                                            <ul className="bc_IIcol">
                                                <li className="bc_IIcol">
                                                    <div className="inPgBcHalfBlk">
                                                        <div className="inPgBcTextBlk">
                                                            <Link to="/"><div className="bcMainTxt">Home</div></Link>
                                                            <div className="bcSubTxt">/ About Company</div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="bc_IIcol">
                                                    <ul className="bcPointsSlanting">
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">Strong network of our branches</li>
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">More than 5 lakh satisfied customers</li>
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">Diversified loan options for every need</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="aboutUs">
                            <div className="widthfix_10px">
                                <ul className="aboutIIcol">
                                    <li className="aboutIIcol">
                                        <ul className="aboutLeftNavi">
                                            <li className="aboutLeftNavi">
                                                <Link to="#" className="current">About Company</Link>
                                            </li>
                                            <li className="aboutLeftNavi">
                                                <Link to="/management">Management Team</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="aboutIIcol">
                                        <ul className="aboutIISubCol">
                                            <li className="aboutIISubCol">
                                                <div className="aboutHeadText">About Moham Capital</div>
                                                <div className="aboutText"><b>MOHAM Capital PVT. LTD. (MCPL)</b> was incorporated in the year 2018 to meet the financial requisites of retail consumers. MCPL plays a vital role in providing hassle free finance for 2 wheeler loans and set foot in gold, business and consumer loans.</div>
                                                <div className="aboutText">MCPL is the most diversified NBFC in the country. MCPL is a RBI registered NBFC with its head office in Bangalore. Our team possesses professionals who have hands on experience. Our all-inclusive portfolio is a prototype which empowers you to superintend your aspiration which would improve your lifestyle, nothing equals the exhilaration of owning your own commodity business.</div>
                                            </li>
                                            <li className="aboutIISubCol">
                                                <div className="aboutContactBlk">
                                                    <div className="abtContactIcon"></div>
                                                    <div className="abtContactTxtHead">Talk to Sales</div>
                                                    <div className="abtContactTxt">Want to discuss about a loan? </div>
                                                    <div className="abtContactTxt">Just pick up the phone to chat with a member of our sales team.</div>
                                                    <div className="abtContactPhNo">+91 80 26496555</div>
                                                </div>
                                            </li>
                                        </ul>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
