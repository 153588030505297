import CreditScore from "./creditScore";
import EasyApply from "./easyApply";
import Banner from "./banner";

const Component = (props) => {

    const { show, type, OnCloseClick } = props;

    if (type === 'BANNER') {
        return <Banner show={show} onClose={OnCloseClick} />;
    } else if (type === 'CREDTISCORE') {
        return <CreditScore show={show} onClose={OnCloseClick} />;
    } else if (type === 'EASYAPPLY') {
        return <EasyApply show={show} onClose={OnCloseClick} />;
    }

    return null;
};

export default Component;