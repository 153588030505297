import { React, Container, Link } from "common";

const Component = () => {
    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_about">
                            <div className="inPgBcShadeBlk">
                                <div className="inPgBcCrossBlk">
                                    <div className="widthfix_10px">
                                        <ul className="bc_IIcol">
                                            <li className="bc_IIcol">
                                                <div className="inPgBcHalfBlk">
                                                    <div className="inPgBcTextBlk">
                                                        <Link to="/"><div className="bcMainTxt">Home</div></Link>
                                                        <div className="bcSubTxt">/ Gold Loan Charges</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="bc_IIcol">
                                                <ul className="bcPointsSlanting">
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Strong network of our branches</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">More than 5 lakh satisfied customers</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Diversified loan options for every need</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="charges">
                            <div className="widthfix_10px">
                                <ul className="aboutIIcol">
                                    <li className="aboutIIcol">
                                        <ul className="aboutLeftNavi">
                                            <li className="aboutLeftNavi">
                                                <Link to="/chargesbusiness" >BL &amp; PL Charges</Link>
                                            </li>
                                            <li className="aboutLeftNavi">
                                                <Link to="/chargesbike">Two Wheeler Loan Charges</Link>
                                            </li>
                                            <li className="aboutLeftNavi">
                                                <Link to="/chargesgold" className="current">Gold Loan Charges</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="aboutIIcol">
                                        <ul className="aboutIISubCol">
                                            <li className="aboutIISubCol">
                                                <div className="aboutHeadText">Gold Loan Charges</div>

                                                <table className="chargesTable">
                                                    <thead>
                                                        <tr>
                                                            <th>Gold Loans Schedule of charges Charges (Inclusive of GST)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Late Repayment Charges 3% per annum on outstanding balance.
                                                                Foreclosure Charges: Bullet Repayment Loans: If full loan amount is repaid within 7 days, minimum 7 days interest period to be
                                                                serviced.</td>
                                                        </tr>
                                                        <tr>
                                                            <td>EMI Loans: Foreclosure period to be 30 days for EMI.
                                                                Cases and foreclosure charges shall be maximum 2% of the amount outstanding.</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Bounce Charges INR 500</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Documentation Fees for  Fresh Loans : Up to 0.50%* of the loan amount.</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Documentation Fees for Top-up Loans: Up to 0.50%* of the top up loan amount.</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Non-utilization of credit line : Up to INR 200 per quarter inclusive of taxes (if gold stored within branch safe and credit not utilized by customer during a 6-month period)</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Safe Custody Charges Up to INR 5 per gram per month, payable at the time of closure or renewal</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Doorstep Gold Loan Charges Up to INR 500 for Doorstep Gold Loan Service</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Doorstep collection charges Up to INR 150 per customer for every payment or recovery made at the doorstep</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Auction Notice charges (Ticket sizes up to INR 1.5 Lakhs) : Charges for the first three ordinary notices - Up to INR 40 per notice. Charge for the fourth notice (registered) sent to customer - Up to INR 100 per notice</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Recovery Charges (Ticket sizes up to INR 1.5 Lakhs) Recovery Charges - Up to INR 500</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Advertisement Fee (Ticket sizes up to INR 1.5 Lakhs) Advertisement fee - Up to INR 200</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Auction Notice charges (Ticket sizes above INR 1.5 Lakhs) : Charges for the first three ordinary notices - Up to INR50 per notice Charge for the fourth notice (registered) sent to customer - Up to INR 150 per notice</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Recovery Charges (Ticket sizes above INR 1.5 Lakhs) : Recovery Charges – Up to INR 750</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Advertisement Fee (Ticket sizes above INR 1.5 Lakhs) Advertisement fee - Up to INR 300</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Pledge Card Lost Charges Up to INR 25 per new Pledge Card generated</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Stamp Duty/Stamping/Franking Charges Payable as per state laws and deducted upfront from Loan amount</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Credit Verification Charges As per actuals, applied during disbursal</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SMS Charges Up to INR 5 per quarter at the time of closure or renewal</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </li>
                                            <li className="aboutIISubCol">
                                                <div className="aboutContactBlk">
                                                    <div className="abtContactIcon"></div>
                                                    <div className="abtContactTxtHead">Talk to Sales</div>
                                                    <div className="abtContactTxt">Want to discuss about a loan? </div>
                                                    <div className="abtContactTxt">Just pick up the phone to chat with a member of our sales team.</div>
                                                    <div className="abtContactPhNo">+91 80 26496555</div>
                                                </div>
                                            </li>
                                        </ul>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;
