import API from "./api";
import session from "../helper/session";

const GetProfile = async () => {
    try {
        const token = await session.GetHeader();
        const res = await API.get(`profile`, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const SetProfile = async (input) => {
    try {
        const token = await session.GetHeader();
        const res = await API.post(`profile`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const SetPassword = async (input) => {
    try {
        const token = await session.GetHeader();
        const res = await API.post(`profile/password`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

export { GetProfile, SetProfile, SetPassword };
