import { React, Link, Container, Session, useNavigate } from "common";

const Component = () => {

    const Navigate = useNavigate();

    const OnLoanTypeSelected = (e, type, name) => {
        e.preventDefault();
        Session.Store('loanTypeId', type);
        Session.Store('loanTypeName', name);
        Navigate("/apply");
    }

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_durables">
                            <div className="inPgBcShadeBlk">
                                <div className="inPgBcCrossBlk">
                                    <div className="widthfix_10px">
                                        <ul className="bc_IIcol">
                                            <li className="bc_IIcol">
                                                <div className="inPgBcHalfBlk">
                                                    <div className="inPgBcTextBlk">
                                                        <Link to="/loans"><div className="bcMainTxt">Loans</div></Link>
                                                        <div className="bcSubTxt">/ Consumer Durables Loan</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="bc_IIcol">
                                                <ul className="bcPointsSlanting">
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">On-spot loan approval fom 10000 to 15 lakh</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Up to 100% financing on consumer durable goods</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">No Cost EMI available from leading banks and NBFCs</li>
                                                </ul>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="loanInpg">
                            <div className="widthfix_10px">
                                <ul className="loanIIcolContainer">
                                    <li className="loanIIcolContainer">
                                        <div className="loanImgBlk">
                                            <div className="consumerDurableImage"></div>
                                        </div>
                                    </li>
                                    <li className="loanIIcolContainer">
                                        <div className="loanContentBlk">
                                            <div className="loan_headTxt">Buy now and <span className="headTxtRed"> pay later </span>with ease</div>
                                            <div className="loan_heroTxt">Shop smarter with our consumer durables loan</div>
                                            <ul className="loan_uspPoint">
                                                <li className="loan_uspPoint">Flexible Repayment Terms</li>
                                                <li className="loan_uspPoint">Minimal Documentation</li>
                                                <li className="loan_uspPoint">Competitive Interest Rates</li>
                                                <li className="loan_uspPoint">No Hidden Fee or Charges</li>
                                            </ul>
                                            <Link to="/apply" onClick={(e) => OnLoanTypeSelected(e, 4, "Consumer Durables Loan")}><input type="button" className="loan_usp_button" value="Apply Now" /></Link>
                                        </div>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div >
                </div >
            </Container>
        </>
    )
}

export default Component;