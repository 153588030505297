import { React, Helper, useTimerSession, Link, Container, TextInput } from "common";
import { ThreeSteps, LoanTypeSelection } from "screens/shared/components";
import { LoanApi, LookUpApi, CommonApi } from "services";
import OtpVerify from "screens/shared/popups/otpverify";
import { AddLOSApplication } from "services/losLoanApi";

import {
    RenderTwoWheelerComponent,
    RenderPersonaDetails,
    RenderLocatedDetails,
    RenderEmploymentDetails,
    CobrPersonalDetails,
    CobrLocatedDetails,
    CobrEmploymentDetails,
    GuarantorPersonalDetails,
    GuarantorLocatedDetails,
    GuarantorEmploymentDetails,
    RenderGoldPersonaDetails,
    RenderDocumentInfo,
    RenderUploadDocument
} from "./childs";

const Component = () => {

    const [loanId] = useTimerSession('loanTypeId');
    const [loanName] = useTimerSession('loanTypeName');
    const [initlized, setInitlized] = React.useState(false);
    const [row, setRow] = React.useState({});
    const [state, setState] = React.useState(false);
    const [success, setSuccess] = React.useState(null);
    const [loanTypes, setLoanTypes] = React.useState([]);
    const [docProofTypes, setDocProofTypes] = React.useState([]);
    const [errors, setErrors] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [showOtp, setShowOtp] = React.useState(false);
    const [otpDialogState, setOtpDialogState] = React.useState(false);
    const [disclaimer, setDisclaimer] = React.useState(false);
    const [applicationIdChange, setApplicationIdChange] = React.useState(false);
    const [isLoanTypeChange, setIsLoanTypeChange] = React.useState(false);
    const [defaRow, setDefaRow] = React.useState(false);
    const [showApplicationId, setShowApplicationId] = React.useState(false);
    const [token] = useTimerSession('token');

    const contentId = token ? 'content' : 'content_fullHeader';
    const applyBgStyle = token ? 'applyPg_loggedIn' : 'applyPg';

    const [loanTypeId, setLoanTypeId] = React.useState(loanId);
    const [loanTypeName, setLoanTypeName] = React.useState(loanName);

    const OnBlurError = (name, val, clear) => {
        setSuccess(null);
        let _errors = [];
        if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
        if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
        if (name === "applicationId") setApplicationIdChange(true);
        setErrors(_errors);
        setError(val);
    };

    const OnInputChanged = async (path, value, index, type) => {
        let newRow = row;
        if (type === 'ZIP') {
            for (var key in path) {
                if (Object.prototype.hasOwnProperty.call(path, key)) {
                    newRow = Helper.ModifyJsonArrayObject4(newRow, key, path[key]);
                    newRow = Helper.ModifyJsonArrayObject4(newRow, `${key}readonly`, !Helper.IsNullValue(path[key]));
                }
            }
        } else {
            newRow = Helper.ModifyJsonArrayObject4(row, path, value);
        }

        setRow(newRow);
        setState(!state);
    }

    const OnFileItemDelete = async (name) => {
        let newRow = row;
        delete newRow.documentproof[name];
        setRow(newRow);
        setState(!state);
    }

    const OnInputClicked = (name) => {
        setSuccess(null);
        let _err = null;
        if (errors && errors.length > 0) {
            let _findIndex = errors.findIndex((x) => x.field === name);
            if (_findIndex > -1) {
                _err = errors[_findIndex].value;
            }
        }
        setError(_err);
    };

    const OnRecordValidate = () => {
        setError("");
        setErrors([]);
        const { errors, valid } = Helper.IsFormValid('container');

        if (!valid) {
            setErrors(errors);
            setError("Fill all required details");
            return false;
        }
        return true;
    };

    const OnApplyClicked = async (e) => {
        e.preventDefault();
        if (Helper.IsNullValue(loanTypeName)) {
            setError('Please select a loanType');
            return;
        }
        if (!OnRecordValidate()) return;
        row.loanTypeId = loanTypeId;
        row.loanType = loanTypeName;
        setOtpDialogState(!otpDialogState);
        setShowOtp(true);
    };

    const UpdateLoan = async () => {
        global.Busy(true);

        let rslt = await LoanApi.AddApplication(row);
        global.Busy(false);
        if (rslt.status !== 100) {
            setErrors(rslt.errors);
            setError(rslt.statusText);
            return;
        } else {
            /* Added the below for MCPL Loan Origin */
            /************************/
            const timestamp = Date.now().toString().substring(0, 11);

            row.applicationId = timestamp;
            row.level = 2;
            row.preapproval = 0;
            row.source = "Internet";
            row.environment = Helper.environment;

            /************************/

            await AddLOSApplication(row);
            setSuccess("Application Submitted Succesfully");
            setDefaRow(!defaRow);
        }
    }

    const OnCancelClicked = () => { };

    const OnOTPStatus = async (status) => {
        setShowOtp(false);
        if (status) await UpdateLoan();
    };

    const OnDisclaimerClicked = (e) => {
        e.preventDefault();
        setDisclaimer(!disclaimer);
    }

    const FetchResults = async () => {
        global.Busy(true);
        let rslt = await LookUpApi.GetLoanTypes();
        if (rslt.status === 100) {
            setLoanTypes(rslt.data);
        }

        rslt = await CommonApi.GetDocumentTypes('applynow');
        if (rslt.status === 100) {
            setDocProofTypes(rslt.data);
        }

        global.Busy(false);
    };

    if (initlized) {
        setInitlized(false);
        setErrors([]);
        setError(null);
        setDisclaimer(false);
        setLoanTypes([]);
        setDocProofTypes([]);
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    React.useEffect(() => {
        if (!isLoanTypeChange) {
            setIsLoanTypeChange(false);
            setDefaRow(!defaRow);
        }
        setErrors([]);
        setError(null);
        setSuccess(null);
        let rootElm = Object.values(document.getElementById("applyLoan").querySelectorAll("input,select"));
        let list = rootElm.filter((x) => !Helper.IsNullValue(x.name));
        let index = 1;
        for (var l of list) {
            l.setAttribute('tabIndex', index);
            index++;
        }
    }, [loanTypeId]);

    React.useEffect(() => {
        const timestamp = Date.now().toString().substring(0, 11);
        setRow({ "applicationId": timestamp });
        setState(!state);
    }, [defaRow]);

    React.useEffect(() => {
        const fn = async () => {
            setApplicationIdChange(false);
            if (Helper.IsNullValue(row?.applicationId)) return;
            global.Busy(true);
            let rslt = await LoanApi.GetApplication(row?.applicationId);
            if (rslt.status === 100) {
                let _tmp = rslt.data;
                setLoanTypeId(_tmp.loanTypeId);
                setLoanTypeName(_tmp.loanType);
                setRow(_tmp);
                setState(!state);
                setIsLoanTypeChange(true);
            }

            global.Busy(false);
        }

        if (applicationIdChange) fn();
    }, [applicationIdChange]);

    React.useEffect(() => {
        setLoanTypeId(loanId);
        setLoanTypeName(loanName);
    }, [loanId, loanName])

    return (
        <>
            <Container>
                <div id="container">
                    <div id={contentId}>
                        {!token && (
                            <div id="heroImg_loan">
                                <div className="inPgBcShadeBlk">
                                    <div className="inPgBcCrossBlk">
                                        <div className="widthfix_10px">
                                            <ul className="bc_IIcol">
                                                <li className="bc_IIcol">
                                                    <div className="inPgBcHalfBlk">
                                                        <div className="inPgBcTextBlk">
                                                            <div className="bcMainTxt">Apply for your Loan</div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="bc_IIcol">
                                                    <ul className="bcPointsSlanting">
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">Simple and fast application process</li>
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">Flexible and easy repayment periods</li>
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">Approvals happen on the same-day</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={applyBgStyle}>
                            <div className="widthfix_10px">
                                <ul className="pgIIcol_left400">
                                    <li className="pgIIcol_left400">
                                        <ThreeSteps />
                                    </li>
                                    <li className="pgIIcol_left400" id="applyLoan">
                                        <div className="applyShadowBlk">
                                            <div className="applyHead">
                                                What kind of loan are you looking for <span
                                                    type="button"
                                                    value="ApplyID"
                                                    className="but_Application"
                                                    onClick={() => setShowApplicationId(!showApplicationId)}
                                                >(ID)</span>?

                                                {Helper.ToBool(showApplicationId) &&
                                                    (<ul className="contentIIIcol">
                                                        <li className="contentIIIcol">
                                                            <div className="applyContentHead">Application ID</div>
                                                            <div className="applyTxtField">
                                                                <TextInput
                                                                    errors={errors}
                                                                    path={"applicationId"}
                                                                    value={row?.applicationId}
                                                                    onInputChildChanged={OnInputChanged}
                                                                    onBlurError={OnBlurError}
                                                                    onInputClicked={OnInputClicked}
                                                                    requiredMessage={'Application-Id is required'}
                                                                    placeholder={"Application ID"}
                                                                    dataRequired={true}
                                                                    required={true}
                                                                />
                                                            </div>
                                                        </li>
                                                    </ul>)}
                                            </div>
                                            <LoanTypeSelection className="applyLoanType" loanTypes={loanTypes} loanTypeId={loanTypeId} loanTypeName={loanTypeName} />
                                        </div>
                                        {Helper.ToInteger(loanTypeId) === 1 && <RenderTwoWheelerComponent row={row?.twowheeler} errors={errors}
                                            onBlurError={OnBlurError} onInputClicked={OnInputClicked} loanTypeName={loanTypeName} onInputChanged={OnInputChanged} />}
                                        <div className="applyShadowBlk">
                                            {Helper.ToInteger(loanTypeId) === 2 ? <RenderGoldPersonaDetails row={row?.personaldetails} errors={errors} onBlurError={OnBlurError}
                                                onInputClicked={OnInputClicked} onInputChanged={OnInputChanged} />
                                                : <RenderPersonaDetails row={row?.personaldetails} errors={errors} onBlurError={OnBlurError}
                                                    onInputClicked={OnInputClicked} onInputChanged={OnInputChanged} />}

                                            <RenderLocatedDetails row={row?.locationdetails} errors={errors} onBlurError={OnBlurError}
                                                onInputClicked={OnInputClicked} onInputChanged={OnInputChanged}
                                            />

                                            <RenderEmploymentDetails row={row?.employmentdetails} errors={errors} onBlurError={OnBlurError}
                                                onInputClicked={OnInputClicked} onInputChanged={OnInputChanged}
                                            />

                                            {/* Co-Borrower Details */}
                                            {Helper.ToBool(row?.personaldetails?.coborrower) &&
                                                <>
                                                    <CobrPersonalDetails row={row?.coborrowerpersonaldetails} errors={errors} onBlurError={OnBlurError}
                                                        onInputClicked={OnInputClicked} onInputChanged={OnInputChanged} />

                                                    <CobrLocatedDetails row={row?.coborrowerlocationdetails} errors={errors} onBlurError={OnBlurError}
                                                        onInputClicked={OnInputClicked} onInputChanged={OnInputChanged} />

                                                    <CobrEmploymentDetails row={row?.coborroweremploymentdetails} errors={errors} onBlurError={OnBlurError}
                                                        onInputClicked={OnInputClicked} onInputChanged={OnInputChanged} />
                                                </>
                                            }

                                            {/* Guarantor Details */}
                                            {Helper.ToBool(row?.personaldetails?.guarantor) &&
                                                <>
                                                    <GuarantorPersonalDetails row={row?.guarantorpersonaldetails} errors={errors} onBlurError={OnBlurError}
                                                        onInputClicked={OnInputClicked} onInputChanged={OnInputChanged} />

                                                    <GuarantorLocatedDetails row={row?.guarantorlocationdetails} errors={errors} onBlurError={OnBlurError}
                                                        onInputClicked={OnInputClicked} onInputChanged={OnInputChanged} />

                                                    <GuarantorEmploymentDetails row={row?.guarantoremploymentdetails} errors={errors} onBlurError={OnBlurError}
                                                        onInputClicked={OnInputClicked} onInputChanged={OnInputChanged} />
                                                </>
                                            }

                                            <RenderUploadDocument rootClassName='applyHead_borderBottom' documents={row?.documentproof} onFileItemDelete={OnFileItemDelete}
                                                doctypes={docProofTypes} onInputChanged={OnInputChanged} />

                                            <div className="applyButLabel">
                                                <input
                                                    type="button"
                                                    value="Apply"
                                                    className="but_apply"
                                                    onClick={(e) => OnApplyClicked(e)}
                                                />
                                                <input
                                                    type="button"
                                                    value="Cancel"
                                                    className="but_apply"
                                                    onClick={(e) => OnCancelClicked(e)}
                                                />
                                            </div>

                                            <div className="disclaimerAlert">
                                                <div className="disclaimerLink">
                                                    By clicking on Apply, you agree to our <Link to="#" onClick={(e) => OnDisclaimerClicked(e)}>Disclaimer</Link>
                                                </div>
                                                {disclaimer && (
                                                    <div className="disclaimerText">By applying, you are providing “written instructions” to Moham Capital Pvt Ltd. under the Fair Credit Reporting Act authorizing Moham Capital Pvt Ltd.. to obtain information from your personal credit profile or other information from Credit Bureau. You authorize Moham Capital Pvt Ltd. to obtain such information solely to conduct a pre-qualification for credit.</div>
                                                )}
                                            </div>

                                            {error && <div className="errormessage">{error}</div>}
                                            {success && <div className="successmessage">{success}</div>}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <OtpVerify
                    show={showOtp}
                    onOTPStatus={OnOTPStatus}
                    dlgState={otpDialogState}
                    phoneNumber={row?.personaldetails?.phoneNumber}
                />
            </Container>
        </>
    )
}

export default Component; 
