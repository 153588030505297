import {
    React, Link, Container, Helper, useNavigate,
    useState, useEffect, DoughnutChart, Session
} from "common";

import { FinstaApi } from "services";

const Component = () => {
    const [initlized, setInitlized] = React.useState(false);
    const [loanAccounts, setLoanAccounts] = React.useState([]);

    const NavigateTo = useNavigate();

    const FetchResults = async () => {
        global.Busy(true);
        const statusId = 1;
        let rslt = await FinstaApi.GetLoanAccountSummary(statusId);
        global.Busy(false);
        if (rslt.status === 100) {
            setLoanAccounts(rslt.data);
        }
    };

    if (initlized) {
        setInitlized(false);
        setLoanAccounts([]);
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    const OnLoanViewClicked = (e, loanNumber) => {
        e.preventDefault();
        Session.Store('loanNumber', loanNumber);
        NavigateTo("/cld");
    }


    return (
        <>
            <Container>
                <div id="container">
                    <div id="content">
                        <ul className="bc_inPage">
                            <div className="widthfix_10px">
                                <li className="bc_inPage"><Link to="/cd">Dashboard</Link></li>
                                <li className="bc_inPage">Loan Account Summary</li>
                            </div>
                        </ul>
                        <div className="widthfix_10px">
                            <div className="loanListDetails">
                                <div className="loanlistContentBlk">
                                    <div className="loanlistHeadBlk">
                                        <div className="blkName">Loan Account Summary</div>
                                    </div>
                                    <div className="contentarea_table">
                                        <div className="loanListTable_head">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>Loan ID</td>
                                                        <td>Loan Type</td>
                                                        <td>Opening Date</td>
                                                        <td>Maturity Date</td>
                                                        <td>Loan Amount</td>
                                                        <td>Interest Rate</td>
                                                        <td>Amount Paid</td>
                                                        <td>Outstanding</td>
                                                        <td>Term</td>
                                                        <td>EMI</td>
                                                        <td>Payment Mode</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="loanListTable">
                                            <table>
                                                <tbody>
                                                    {loanAccounts && loanAccounts.length > 0 &&
                                                        loanAccounts.map((x, index) => (
                                                            <tr key={index}>
                                                                <td><Link to="#" onClick={(e) => OnLoanViewClicked(e, x.loanNumber)}>{x.loanNumber}</Link></td>
                                                                <td>{x.loanType}</td>
                                                                <td>{Helper.ToDate(x.startDate, "YYYY-MM-DD")}</td>
                                                                <td>{Helper.ToDate(x.maturityDate, "YYYY-MM-DD")}</td>
                                                                <td>{Helper.INRCurrencyFormat(x?.loanAmount, true)}</td>
                                                                <td>{Helper.ToFixed(x?.rateOfInterest, 2)}%</td>
                                                                <td>{Helper.INRCurrencyFormat(x?.loanAmount - x?.balanceAmount, true)}</td>
                                                                <td>{Helper.INRCurrencyFormat(x?.balanceAmount, true)}</td>
                                                                <td>{x.tenure}</td>
                                                                <td>{Helper.INRCurrencyFormat(x?.emi, true)}</td>
                                                                <td>{x.paymentMode}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
}

export default Component;