import { React, Support, DropDown, TextInput, DatePicker } from "common";

const RenderGoldPersonaDetails = (props) => {

    const { row, errors, onInputChanged, onBlurError, onInputClicked, headtext } = props;

    let rootpath = 'personaldetails';

    return (
        <>
            <div className="applyHead_borderBottom">
                Personal Details
            </div>
            <ul className="contentIIIcol">
                <li className="contentIIIcol">
                    <div className="applyContentHead">First Name</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.firstName`}
                            value={row?.firstName}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={'First Name is required'}
                            dataRequired={true}
                            required={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Middle Name</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.middleName`}
                            value={row?.middleName}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Last Name</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.lastName`}
                            value={row?.lastName}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={'Last Name is required'}
                            dataRequired={true}
                            required={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Phone Number</div><div className="formMandatory">*</div>
                    <div className="applyNoteText">OTP Will Be Sent</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.phoneNumber`}
                            valuetype={"PHONE"}
                            value={row?.phoneNumber}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={'Phone Number is required'}
                            validationMessage={'Enter valid Phone Number'}
                            dataRequired={true}
                            validate={true}
                            required={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Email Address</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.emailAddress`}
                            valuetype={"EMAIL"}
                            value={row?.emailAddress}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            validationMessage={'Enter valid email-id'}
                            dataRequired={false}
                            validate={true}
                            required={false}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Marital Status</div><div className="formMandatory">*</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.maritalStatus`}
                            value={row?.maritalStatus}
                            onInputChildChanged={onInputChanged}
                            items={Support.MaritalTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                            dataRequired={true}
                            indexValue={1}
                            requiredMessage={"Marital Status is required"}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Gender</div><div className="formMandatory">*</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.gender`}
                            value={row?.gender}
                            onInputChildChanged={onInputChanged}
                            items={Support.GenderTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                            dataRequired={true}
                            indexValue={1}
                            requiredMessage={"Gender is required"}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Aadhar</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.aadhar`}
                            valuetype={"AADHAR"}
                            value={row?.aadhar}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={'Aadhar is required'}
                            validationMessage={'Enter valid Aadhar'}
                            dataRequired={true}
                            required={true}
                            validate={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Voter ID</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.voterid`}
                            valuetype={"VOTERID"}
                            value={row?.voterid}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            validationMessage={'Enter valid Voter ID'}
                            dataRequired={false}
                            required={false}
                            validate={true}
                            uppercase={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">How did you hear about MCPL</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.hearabout`}
                            value={row?.hearabout}
                            onInputChildChanged={onInputChanged}
                            items={Support.HearAboutTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                        />
                    </div>
                </li>
            </ul>
            <div className="spacer_20margin_bottom"></div>
        </>
    );

}

export default RenderGoldPersonaDetails;