import { React, TextInput, DatePicker, Helper } from "common";

const Component = (props) => {

    const { row, errors, salesPerson, onInputChanged, onBlurError, onInputClicked, OnSalesPersonChanged } = props;

    const rootpath = 'coBorrower';

    return (
        <>
            <div className="coBrowwerHead" >
                Co-Borrower Details
            </div>

            <ul className="contentIIcol">
                <li className="contentIIcol">
                    <div className="preApproveContentHead">Name</div><div className="formMandatory">*</div>
                    <div className="preApproveTxtField">
                        <TextInput
                            path={`${rootpath}.name`}
                            value={row?.name}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            errors={errors}
                            requiredMessage={'Name is required'}
                            placeholder={"Name as per KYC"}
                            dataRequired={true}
                            required={true}
                        />
                    </div>
                </li>
                <li className="contentIIcol">
                    <div className="preApproveContentHead">Mobile Number</div><div className="formMandatory">*</div>
                    <div className="preApproveTxtField">
                        <TextInput
                            path={`${rootpath}.phone`}
                            value={row?.phone}
                            valuetype={"PHONE"}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            errors={errors}
                            requiredMessage={'Mobile Number is required'}
                            validationMessage={'Enter valid Mobile Number'}
                            dataRequired={true}
                            required={true}
                            validate={true}
                        />
                    </div>
                </li>
                <li className="contentIIcol">
                    <div className="preApproveContentHead">Email Address</div><div className="formMandatory">*</div>
                    <div className="preApproveTxtField">
                        <TextInput
                            path={`${rootpath}.email`}
                            value={row?.email}
                            valuetype={"EMAIL"}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            errors={errors}
                            requiredMessage={'Email-Id is required'}
                            validationMessage={'Enter valid Email-Id'}
                            dataRequired={true}
                            required={true}
                            validate={true}
                        />
                    </div>
                </li>
                <li className="contentIIcol">
                    <div className="preApproveContentHead">PAN</div>
                    <div className="preApproveTxtField">
                        <TextInput
                            path={`${rootpath}.pan`}
                            value={row?.pan}
                            valuetype={"PAN"}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            errors={errors}
                            requiredMessage={'PAN is required'}
                            validationMessage={'Enter valid PAN'}
                            dataRequired={false}
                            required={false}
                            validate={true}
                            uppercase={true}
                            maxLength={10}
                        />
                    </div>
                </li>
                <li className="contentIIcol">
                    <div className="preApproveContentHead">Aadhar</div><div className="formMandatory">*</div>
                    <div className="preApproveTxtField">
                        <TextInput
                            path={`${rootpath}.aadhar`}
                            value={row?.aadhar}
                            valuetype={"AADHAR"}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            errors={errors}
                            requiredMessage={'Aadhar is required'}
                            validationMessage={'Enter valid Aadhar'}
                            dataRequired={true}
                            required={true}
                            validate={true}
                            maxLength={12}
                        />
                    </div>
                </li>
                <li className="contentIIcol">
                    <div className="preApproveContentHead">DOB</div><div className="formMandatory">*</div>
                    <div className="preApproveTxtField">
                        <DatePicker
                            path={`${rootpath}.birthDate`}
                            placeholder="Date Of Birth"
                            value={row?.birthDate}
                            valuetype={'DOB'}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            errors={errors}
                            age={18}
                            dateFormat="dd/MM/yyyy"
                            required={true}
                            requiredMessage={'Date of Birth is required'}
                        />
                    </div>
                </li>
                <li className="contentIIcol">
                    <div className="preApproveContentHead">Address Line 1</div><div className="formMandatory">*</div>
                    <div className="preApproveTxtField">
                        {/* <GeoLocation name={`${path}.addressLine1`}> */}
                        <TextInput
                            path={`${rootpath}.addressLine1`}
                            value={row?.addressLine1}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            errors={errors}
                            requiredMessage={'Address Line 1 is required'}
                            dataRequired={true}
                            required={true}
                        />
                        {/* </GeoLocation> */}
                    </div>
                </li>
                <li className="contentIIcol">
                    <div className="preApproveContentHead">Address Line 2</div>
                    <div className="preApproveTxtField">
                        <TextInput
                            path={`${rootpath}.addressLine2`}
                            value={row?.addressLine2}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            errors={errors}
                            dataRequired={false}
                            required={false}
                        />
                    </div>
                </li>
                <li className="contentIIcol">
                    <div className="preApproveContentHead">Pin Code</div><div className="formMandatory">*</div>
                    <div className="preApproveTxtField">
                        <TextInput
                            path={`${rootpath}.zipcode`}
                            value={row?.zipcode}
                            valuetype={"ZIP"}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            errors={errors}
                            requiredMessage={'Pin Code is required'}
                            validationMessage={'Enter valid Pin Code'}
                            dataRequired={true}
                            required={true}
                            validate={true}
                        />
                    </div>
                </li>
                <li className="contentIIcol">
                    <div className="preApproveContentHead">State</div><div className="formMandatory">*</div>
                    <div className="preApproveTxtField">
                        <TextInput
                            path={`${rootpath}.state`}
                            value={row?.state}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            errors={errors}
                            requiredMessage={"State is required"}
                            dataRequired={true}
                            required={true}
                            readOnly={Helper.ToBool(row?.statereadonly)}
                        />
                    </div>
                </li>
                <li className="contentIIcol">
                    <div className="preApproveContentHead">City / Place</div><div className="formMandatory">*</div>
                    <div className="preApproveTxtField">
                        <TextInput
                            path={`${rootpath}.city`}
                            value={row?.city}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            errors={errors}
                            requiredMessage={"City is required"}
                            dataRequired={true}
                            required={true}
                            readOnly={Helper.ToBool(row?.cityreadonly)}
                        />
                    </div>
                </li>
                <li className="contentIIcol">
                    <div className="preApproveContentHead">District</div><div className="formMandatory">*</div>
                    <div className="preApproveTxtField">
                        <TextInput
                            path={`${rootpath}.district`}
                            value={row?.district}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            errors={errors}
                            requiredMessage={"City is required"}
                            dataRequired={true}
                            required={true}
                            readOnly={Helper.ToBool(row?.districtreadonly)}
                        />
                    </div>
                </li>
                <li className="contentIIcol">
                    <ul className="contentIIcol">
                        <li className="contentIIcol">
                            <div className="preApproveContentHead">Contacted Sales Person ?</div>
                            <div className="preApproveSelectField">
                                <select name="filterType" value={salesPerson}
                                    onChange={(e) => OnSalesPersonChanged(e, true)}
                                >
                                    <option value="0">Select</option>
                                    <option value="1">Yes</option>
                                    <option value="2">No</option>
                                </select>
                            </div>
                        </li>
                        <li className="contentIIcol">
                            <div className="preApproveContentHead">Sales Person Name</div>
                            <div className="preApproveTxtField">
                                <TextInput
                                    path={`${rootpath}.prevexecutive`}
                                    value={row?.prevexecutive}
                                    onInputChildChanged={onInputChanged}
                                    onBlurError={onBlurError}
                                    onInputClicked={onInputClicked}
                                    readOnly={parseInt(salesPerson) !== 1}
                                />
                            </div>
                        </li>
                    </ul>
                </li>
                <li className="contentIIcol">
                    <div className="preApproveContentHead">Monthly Income</div><div className="formMandatory">*</div>
                    <div className="preApproveTxtField">
                        <TextInput
                            path={`${rootpath}.monthlyincome`}
                            value={row?.monthlyincome}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            errors={errors}
                            valuetype={'NUMBERS'}
                            requiredMessage={'Monthly Income is required'}
                            dataRequired={true}
                            required={true}
                        />
                    </div>
                </li>
            </ul>
        </>

    );

}

export default Component;