import { React, useState, useEffect, Helper, Session, RadioInput } from "common";

const Component = ({ className, loanTypes, loanTypeId, loanTypeName }) => {
    const [row, setRow] = useState({});

    useEffect(() => {
        if (!Helper.IsNullValue(loanTypeId)) UpdateRow('loantype', parseInt(loanTypeId));
    }, [loanTypeId, loanTypeName]);

    useEffect(() => {
        if (loanTypes) {
            let _find = loanTypes.find((x) => x.value === parseInt(loanTypeId));
            if (_find) {
                let _name = _find?.displayname;
                Session.Store('loanTypeName', _name);
            }
        }
    }, [loanTypeId, loanTypes]);

    const OnInputChanged = async (path, value, index) => {
        UpdateRow(path, value);
        Session.Store('loanTypeId', value);
        let _find = loanTypes.find((x) => x.value === value);
        if (_find) {
            let _name = _find?.displayname;
            Session.Store('loanTypeName', _name);
        }
    }

    const UpdateRow = (name, value) => {
        setRow((prev) => ({
            ...prev,
            [name]: value
        }));
    }

    return (
        <ul className={className}>
            <RadioInput
                path={`loantype`}
                options={loanTypes}
                value={Helper.ToInteger(row?.loantype)}
                uselabel={false}
                onInputChildChanged={OnInputChanged}
            />
        </ul>
    )
}

export default Component;