import React from 'react';

class UserRating extends React.Component {
    render() {
        const { rating } = this.props;

        return (
            <div>
                {[...Array(5)].map((_, index) => (
                    <span
                        key={index}
                        style={{
                            color: index < rating ? '#f7c119' : '#d3d3d3',
                            fontSize: '1.25rem'
                        }}
                    >
                        &#9733;
                    </span>
                ))}
            </div>
        );
    }
}

export default UserRating;