import {
    React, Link, useTimerSession, Container, Helper,
    Image, TextInput, Session
} from "common";

import { ProfileApi } from "services";

const Component = () => {
    const [initlized, setInitlized] = React.useState(false);
    const [statusid] = useTimerSession('statusid', undefined, 0);
    const [user] = useTimerSession('user', true);
    const [row, setRow] = React.useState({});
    const [errors, setErrors] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [state, setState] = React.useState(false);
    const [success, setSuccess] = React.useState(null);
    const [tabIndex, setTabIndex] = React.useState(0);

    const imgSrc = "https://misjpegs.s3.amazonaws.com/icons/placeholder.png";

    const OnBlurError = (name, val, clear) => {
        setSuccess(null);
        let _errors = [];
        if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
        if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
        setErrors(_errors);
        setError(val);
    };

    const OnInputChanged = async (path, value, index, type) => {
        let newRow = row;
        newRow = Helper.ModifyJsonArrayObject4(row, path, value);
        setRow(newRow);
        setState(!state);
    }

    const OnInputClicked = (name) => {
        setSuccess(null);
        let _err = null;
        if (errors && errors.length > 0) {
            let _findIndex = errors.findIndex((x) => x.field === name);
            if (_findIndex > -1) {
                _err = errors[_findIndex].value;
            }
        }
        setError(_err);
    };

    const OnDummyChange = (e) => { e.preventDefault(); }

    const OnRecordValidate = () => {
        setError("");
        setErrors([]);
        const { errors, valid } = Helper.IsFormValid('profileUserInfoBlk');

        let _errors = errors;

        if (!valid) {
            setErrors(_errors);
            setError("Fill all required details");
            return false;
        }

        if (row?.password !== row?.cpassword) {
            errors.push({ field: "password", value: "Password and Confirm password should match" });
            errors.push({ field: "cpassword", value: "Password and Confirm password should match" });
            setErrors(_errors);
            setError("Fill all required details");
            return false;
        }

        return true;
    };

    const OnUpdateClicked = async (e) => {
        e.preventDefault();
        setSuccess(null);
        setError(null);
        if (!OnRecordValidate()) return;

        global.Busy(true);
        let rslt = await ProfileApi.SetProfile(row);
        global.Busy(false);

        if (rslt.status === 100) {
            let user = {
                name: row.borrowerName,
                custId: null
            };
            Session.StoreJSON("user", user);
            Session.Store("statusid", 1);
            setSuccess("Profile updated successful.");
        } else {
            setError(rslt.statusText);
        }
    };

    const OnUpdatePassword = async (e) => {
        e.preventDefault();
        setSuccess(null);
        setError(null);
        setErrors([]);
        let errors = [];

        const fields = [
            { name: 'nPassword', label: "New Password" },
            { name: 'ncPassword', label: "Confirm Password" }];

        for (let fld of fields) {
            if (Helper.IsNullValue(row[fld.name])) {
                errors.push({ field: fld.name, value: `${fld.label} is required` });
            }
        }

        if (errors.length > 0) {
            setErrors(errors);
            setError("Fill all required details");
            return;
        }

        if (row?.nPassword !== row?.ncPassword) {
            errors.push({ field: "ncPassword", value: "New Password and Confirm password should match" });
            setErrors(errors);
            setError("New Password and Confirm password should match");
            return;
        }

        global.Busy(true);
        let rslt = await ProfileApi.SetPassword(row);
        global.Busy(false);
        if (rslt.status === 100) {
            setSuccess("Profile updated successful.");
            for (let fld of fields) {
                row[fld.name] = "";
            }
        } else {
            setError(rslt.statusText);
        }
    };

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    const FetchResults = async () => {
        global.Busy(true);
        setRow({});
        let rslt = await ProfileApi.GetProfile();
        global.Busy(false);
        if (rslt.status === 100) {
            let data = rslt.data;
            data['proimageok'] = true;
            if (Helper.IsNullValue(data?.proimage)) {
                data['proimage'] = imgSrc;
                data['proimageok'] = false;
            }
            data['proimageok'] = false;
            setRow(data);
        }
    };

    if (initlized) {
        setInitlized(false);
        FetchResults();
    }

    const OnTabIndexChanged = (e, index) => {
        e.preventDefault();
        setSuccess(null);
        setError(null);
        setTabIndex(index);
    };

    return (
        <Container>
            <div id="container">
                <div id="content">
                    <div className="profile">
                        <div className="widthfix_10px">
                            <ul className="Profile_vII_Blk">
                                <li className="Profile_vII_Blk">
                                    <div className="profileName">{row?.borrowerName || "Hello!. User"}</div>
                                    <div className="profile_custID">Customer ID: {row?.custId}</div>
                                    <div className="profilePhotoBlk">
                                        <div className="profilePhoto">
                                            {row?.proimageok && (
                                                <Link to="#" title="Delete Photo"><div className="photoDelete"></div></Link>
                                            )}
                                            <Image src={row?.proimage} alt="Photo" top={row?.proimageok ? undefined : 0} />
                                        </div>
                                    </div>
                                    <div className="profilePhotoUpload">&nbsp;
                                        {/* <input type="button" value="Upload New Photo" className="photoUploadButton" /> */}
                                    </div>
                                </li>
                                <li className="Profile_vII_Blk">
                                    <div className="profilePageHead">Profile Details</div>
                                    <ul className="profileNavi">
                                        <li className="profileNavi"><Link to="#" onClick={(e) => OnTabIndexChanged(e, 0)} className={tabIndex === 0 ? "current" : undefined}>User Information</Link></li>
                                        {parseInt(statusid) > 0 && <li className="profileNavi"><Link to="#" onClick={(e) => OnTabIndexChanged(e, 1)} className={tabIndex === 1 ? "current" : undefined}>Change Password</Link></li>}
                                    </ul>
                                    {tabIndex === 0 && (
                                        <div id="profileUserInfoBlk">
                                            <ul className="profileUserInfo">
                                                <li className="profileUserInfo">
                                                    <div className="profileInfoFieldHeading">Full Name</div><div className="formMandatory">*</div>
                                                    <div className="usericon"></div>
                                                    <div className="textBoxStyle">
                                                        <TextInput
                                                            errors={errors}
                                                            path={`borrowerName`}
                                                            value={row?.borrowerName}
                                                            onInputChildChanged={OnInputChanged}
                                                            onBlurError={OnBlurError}
                                                            onInputClicked={OnInputClicked}
                                                            requiredMessage={'Full Name is required'}
                                                            validationMessage={'Full Name is required'}
                                                            dataRequired={true}
                                                            required={true}
                                                        />
                                                    </div>
                                                </li>
                                                <li className="profileUserInfo">
                                                    <div className="profileInfoFieldHeading">Mobile Number</div><div className="formMandatory">*</div>
                                                    <div className="phoneicon"></div>
                                                    <div className="textBoxStyle">
                                                        <TextInput
                                                            maxLength={10}
                                                            errors={errors}
                                                            path={`phoneNo`}
                                                            value={row?.phoneNo}
                                                            valuetype={"NUMBER"}
                                                            onInputChildChanged={OnInputChanged}
                                                            onBlurError={OnBlurError}
                                                            onInputClicked={OnInputClicked}
                                                            requiredMessage={'Enter valid phone number'}
                                                            validationMessage={'Enter valid phone number'}
                                                            dataRequired={true}
                                                            required={true}
                                                            validate={true}
                                                        />
                                                    </div>
                                                </li>
                                                <li className="profileUserInfo">
                                                    <div className="profileInfoFieldHeading">Email Address</div><div className="formMandatory">*</div>
                                                    <div className="mailicon"></div>
                                                    <div className="textBoxStyle">
                                                        <TextInput
                                                            errors={errors}
                                                            path={`emailId`}
                                                            valuetype={"EMAIL"}
                                                            value={row?.emailId}
                                                            onInputChildChanged={OnInputChanged}
                                                            onBlurError={OnBlurError}
                                                            onInputClicked={OnInputClicked}
                                                            requiredMessage={'Enter valid email-id'}
                                                            validationMessage={'Enter valid email-id'}
                                                            dataRequired={true}
                                                            validate={true}
                                                            required={true}
                                                        />
                                                    </div>
                                                </li>
                                            </ul>
                                            {parseInt(statusid) === 0 && (
                                                <>
                                                    <div className="profileSubHeading">
                                                        <div className="subHeadText">Login Information</div>
                                                    </div>
                                                    <ul className="profileUserInfo">
                                                        <li className="profileUserInfo">
                                                            <div className="profileInfoFieldHeading">Email ID</div>
                                                            <div className="mailicon"></div>
                                                            <div className="textBoxStyle">
                                                                <input readOnly type="email"
                                                                    onChange={(e) => OnDummyChange(e)}
                                                                    value={Helper.ToString(row?.emailId)} />
                                                            </div>
                                                        </li>
                                                        <li className="profileUserInfo">
                                                            <div className="profileNoteText">Your email is your login id. <br />Please set the password if you want to use email as login.</div>
                                                        </li>
                                                        <li className="profileUserInfo">
                                                            <div className="profileInfoFieldHeading">Password</div>
                                                            <div className="pswdicon"></div>
                                                            <div className="textBoxStyle">
                                                                <TextInput
                                                                    inputtype="password"
                                                                    errors={errors}
                                                                    path={`password`}
                                                                    value={row?.password}
                                                                    onInputChildChanged={OnInputChanged}
                                                                    onBlurError={OnBlurError}
                                                                    onInputClicked={OnInputClicked}
                                                                    dataRequired={false}
                                                                    required={false}
                                                                />
                                                            </div>
                                                        </li>
                                                        <li className="profileUserInfo">
                                                            <div className="profileInfoFieldHeading">Confirm Password</div>
                                                            <div className="pswdicon"></div>
                                                            <div className="textBoxStyle">
                                                                <TextInput
                                                                    inputtype="password"
                                                                    errors={errors}
                                                                    path={`cpassword`}
                                                                    value={row?.cpassword}
                                                                    onInputChildChanged={OnInputChanged}
                                                                    onBlurError={OnBlurError}
                                                                    onInputClicked={OnInputClicked}
                                                                    dataRequired={false}
                                                                    required={false}
                                                                />
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </>)}
                                            <div className="profileUpdateBtn_Blk">
                                                <input type="button" className="profileUpdateButton"
                                                    onClick={(e) => OnUpdateClicked(e)}
                                                    value="Update Info" />
                                                {error && <div className="errormessage">{error}</div>}
                                                {success && <div className="successmessage">{success}</div>}
                                            </div>
                                        </div>
                                    )}

                                    {tabIndex === 1 && (
                                        <div id="profileChangePassword">
                                            <ul className="profileUserInfo">
                                                <li className="profileUserInfo">
                                                    <div className="profileInfoFieldHeading">New Password</div><div className="formMandatory">*</div>
                                                    <div className="pswdicon"></div>
                                                    <div className="textBoxStyle">
                                                        <TextInput
                                                            inputtype="password"
                                                            errors={errors}
                                                            path={`nPassword`}
                                                            value={row?.nPassword}
                                                            onInputChildChanged={OnInputChanged}
                                                            onBlurError={OnBlurError}
                                                            onInputClicked={OnInputClicked}
                                                            requiredMessage={'New Password is required'}
                                                            validationMessage={'New Password is required'}
                                                            dataRequired={true}
                                                            required={true} />
                                                    </div>
                                                </li>
                                                <li className="profileUserInfo">
                                                    <div className="profileInfoFieldHeading">Confirm Password</div><div className="formMandatory">*</div>
                                                    <div className="pswdicon"></div>
                                                    <div className="textBoxStyle">
                                                        <TextInput
                                                            inputtype="password"
                                                            errors={errors}
                                                            path={`ncPassword`}
                                                            value={row?.ncPassword}
                                                            onInputChildChanged={OnInputChanged}
                                                            onBlurError={OnBlurError}
                                                            onInputClicked={OnInputClicked}
                                                            requiredMessage={'Confirmation Password is required'}
                                                            validationMessage={'Confirmation Password is required'}
                                                            dataRequired={true}
                                                            required={true} />
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className="profileUpdateBtn_Blk">
                                                <input type="button" className="profileUpdateButton" value="Update Password" onClick={(e) => OnUpdatePassword(e)} />
                                                {error && <div className="errormessage">{error}</div>}
                                                {success && <div className="successmessage">{success}</div>}
                                            </div>
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default Component;