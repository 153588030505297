const Component = () => {
    return (
        <div id="disclaimer_pop" className="pop_disable_bg">
            <div className="disclaimer_pop">
                <div className="disclaimerPop_ContentArea">
                    <div className="disclaimerPop_Content">Vehicle price may vary based on variants</div>
                    <div className="disclaimerPop_Close_But_Label">
                        <input type="button" className="disclaimerPop_Close_But" value="Close" onClick={() => window.hide("disclaimer_pop")} />
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Component;