import { React, Helper, Link, TextInput, useState } from "common";
import { CommonApi } from "services";

const Component = (props) => {

    const { mobileview, onClose } = props;

    const containerId = mobileview ? "quickApplyMobile" : "quickApplyHome";

    const [row, setRow] = useState({});
    const [state, setState] = useState(false);
    const [errors, setErrors] = useState([]);
    const [error, setError] = useState(null);

    const OnInputChanged = async (path, value, index) => {
        let newRow = Helper.ModifyJsonArrayObject4(row, path, value);
        setRow(newRow);
        setState(!state);
    }

    const OnBlurError = (name, val, clear) => {
        let _errors = [];
        if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
        if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
        setErrors(_errors);
        setError(val);
    };

    const OnInputClicked = (name) => {
        let _err = null;
        if (errors && errors.length > 0) {
            let _findIndex = errors.findIndex((x) => x.field === name);
            if (_findIndex > -1) {
                _err = errors[_findIndex].value;
            }
        }
        setError(_err);
    };

    const OnRecordValidate = () => {
        setError("");
        setErrors([]);
        const { errors, valid } = Helper.IsFormValid(containerId);

        if (!valid) {
            setErrors(errors);
            setError("Fill all required details");
            return false;
        }
        return true;
    };

    const OnQuickApply = async (e) => {
        e.preventDefault();
        if (!OnRecordValidate()) return;
        global.Busy(true);
        let rslt = await CommonApi.QuickApply(row);
        global.Busy(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        } else {
            setRow({});
            window.showAlert("Thank You. <br />Our team will contact you asap.");
            if (onClose) onClose();
        }
    }

    const OnCloseDialog = (e) => {
        e.preventDefault();
        if (onClose) onClose();
    }

    return (
        <>
            <div className="quickApplyFormBlk" id={containerId}>
                <div className="quickApplyHead">Quick Apply</div>
                <div className="quickApplySub">Please share your details</div>
                <div className="quickApplyTxtField">
                    <TextInput
                        errors={errors}
                        path={`qucikName`}
                        value={row?.qucikName}
                        placeholder="Name*"
                        onInputChildChanged={OnInputChanged}
                        onBlurError={OnBlurError}
                        onInputClicked={OnInputClicked}
                        requiredMessage={'Name is required'}
                        dataRequired={true}
                        required={true}
                    />
                </div>
                <div className="quickApplyTxtField">
                    <TextInput
                        errors={errors}
                        path={`qucikCity`}
                        value={row?.qucikCity}
                        placeholder="City*"
                        onInputChildChanged={OnInputChanged}
                        onBlurError={OnBlurError}
                        onInputClicked={OnInputClicked}
                        requiredMessage={'City is required'}
                        dataRequired={true}
                        required={true}
                    />
                </div>
                <div className="quickApplyTxtField">
                    <TextInput
                        errors={errors}
                        path={`qucikPhone`}
                        value={row?.qucikPhone}
                        valuetype={"PHONE"}
                        onInputChildChanged={OnInputChanged}
                        onBlurError={OnBlurError}
                        onInputClicked={OnInputClicked}
                        validationMessage={'Enter valid Phone Number'}
                        requiredMessage={'Phone Number is required'}
                        dataRequired={true}
                        required={true}
                        validate={true}
                        placeholder="Phone*"
                    />
                </div>
                <div className="quickApplyTxtField">
                    <TextInput
                        errors={errors}
                        path={`qucikEmail`}
                        valuetype={"EMAIL"}
                        value={row?.qucikEmail}
                        onInputChildChanged={OnInputChanged}
                        onBlurError={OnBlurError}
                        onInputClicked={OnInputClicked}
                        requiredMessage={'Email Address is required'}
                        validationMessage={'Enter valid email-id'}
                        dataRequired={true}
                        validate={true}
                        required={true}
                        placeholder="Email*"
                    />
                </div>

                {onClose ? (
                    <ul className="quickApplyBut_IIcol">
                        <li className="quickApplyBut_IIcol">
                            <Link to="#" onClick={(e) => OnQuickApply(e)}><input
                                type="button"
                                value="Apply Now"
                                className="quickApplyButton"
                            /></Link>
                        </li>
                        <li className="quickApplyBut_IIcol">
                            <Link to="#" onClick={(e) => OnCloseDialog(e)} ><input
                                type="button"
                                value="Close"
                                className="quickApplyButton"
                            /></Link>
                        </li>
                    </ul>

                ) : (
                    <ul className="quickApplyBut_Icol">
                        <li className="quickApplyBut_Icol">
                            <Link to="#" onClick={(e) => OnQuickApply(e)}><input
                                type="button"
                                value="Apply Now"
                                className="quickApplyButton"
                            /></Link>
                        </li>
                    </ul>
                )}



                {error && <div className="quickApplyFormSubmitError" style={{ marginTop: 10 }} >{error}</div>}
            </div>
        </>
    )
}

export default Component;