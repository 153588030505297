import { Helper } from "helper";

export const AddLOSApplication = async (input) => {
    return new Promise(async (resolve) => {

        let url = `${Helper.LOSAPIUrl}loan/mcpl`;

        try {
            const res = await fetch(url, {
                method: "POST",
                redirect: 'follow',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(input)
            });

            const json = await res.json();
            if (res.status === 200) {
                return resolve(json);
            }

            return resolve({ status: 900, statusText: json.error.message });

        } catch (error) {
            return resolve({ status: 900, statusText: "Failed connecting to server" });
        }
    })
};