import {
    React, useRef, useState, useEffect, useNavigate,
    Link, Helper, Session, Storage
} from "common";
import { AuthApi, CommonApi } from "services";

const SignInScreen = ({ reset, onClicked }) => {

    const [loginId, setLoginId] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [remember, setRemember] = React.useState(false);
    const [otp, setOtp] = React.useState("");
    const [error, setError] = React.useState(null);

    const [authType, setAuthtype] = React.useState(null);
    const [usePassword, setUsePassword] = React.useState(false);
    const [showOtpResend, setShowOtpResend] = React.useState(false);
    const [otpSent, setOtpSent] = React.useState(false);

    const timeSpan = 15000;

    const NavigateTo = useNavigate();

    const ResetValues = (clear) => {
        if (clear) {
            setLoginId("");
            setPassword("");
            setOtp("");
        }
        setError(null);
        setAuthtype(false);
        setUsePassword(false);
        setShowOtpResend(false);
        setOtpSent(null);
    };

    const OnSignInClicked = async (e) => {
        e.preventDefault();

        setError(null);

        let obj = { scope: authType, key: loginId };

        if (usePassword) {
            if (Helper.IsNullValue(password)) {
                setError("Enter valid password");
                return;
            }
        } else {
            if (Helper.IsNullValue(otp)) {
                setError("Enter valid otp");
                return;
            }
        }

        if (usePassword) {
            obj = { ...obj, password };
        } else {
            obj = { ...obj, otp };
        }

        global.Busy(true);
        const rslt = await AuthApi.SignIn(obj);
        global.Busy(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        }

        Storage.Remove("mcpl");

        if (remember) {
            obj = { scope: authType, key: loginId };
            Storage.StoreJSON("mcpl", obj);
        }

        Session.StoreJSONAsync("user", rslt.data.user);
        Session.Store("statusid", rslt.data.statusid);
        Session.Store("token", rslt.data.token);

        ResetValues();

        onClicked("hide");
        if (parseInt(rslt.data.statusid) === 0) {
            NavigateTo("/pf");
        } else {
            NavigateTo("/cd");
        }

    };

    const OnLoginIdChanged = (e) => {
        e.preventDefault();
        ResetValues();
        const val = e.target.value;
        setLoginId(val);
    }

    const OnGetOtpClicked = async (e) => {
        e.preventDefault();
        setError(null);

        if (Helper.IsNullValue(loginId)) {
            setError("Provide vallid input.");
            return;
        }

        setUsePassword(false);
        setOtpSent(false);
        setShowOtpResend(false);

        global.Busy(true);
        const rlst = await CommonApi.GetOtp(authType, loginId);
        global.Busy(false);
        if (rlst.status === 100) {
            setOtpSent(true);
            setTimeout(() => { setShowOtpResend(true); }, timeSpan);
        }

    }

    const OnUsePasswordClicked = async (e) => {
        e.preventDefault();
        setError(null);

        if (Helper.IsNullValue(loginId)) {
            setError("Provide vallid input.");
            return;
        }

        global.Busy(true);
        const rslt = await AuthApi.IsEmailEXist(loginId);
        global.Busy(false);

        if (rslt.status !== 100) {
            if (rslt.status === 800) {
                setError("No Login found with the email ID");
            } else {
                setError(rslt.statusText);
            }
            return;
        }

        setUsePassword(true);
    }

    const OnOtpEntered = (e) => {
        e.preventDefault();
        setError(null);
        setOtp(e.target.value);
    }

    const OnPasswordEntered = (e) => {
        e.preventDefault();
        setError(null);
        setPassword(e.target.value);
    }

    useEffect(() => {
        setError(null);
        setUsePassword(false);
        setAuthtype(null);
        setOtpSent(false);
        setShowOtpResend(false);
        if (!Helper.IsNullValue(loginId)) {
            if (Helper.IsValidPhone(loginId)) {
                setAuthtype("mobile");
            }
            else if (Helper.IsEmailValid(loginId)) {
                setAuthtype("email");
            }
        }

    }, [loginId]);


    useEffect(() => {
        ResetValues(true);
    }, [reset]);

    useEffect(() => {
        const fn = () => {
            let lastLogin = Storage.RetrieveJSON("mcpl");
            if (!Helper.IsNull(lastLogin?.key)) {
                setLoginId(lastLogin?.key);
            }
        }

        fn();

    }, [])

    return (
        <>
            <div id="signin" style={{ display: "block" }}>
                <div className="si_su_Head">Login to Moham Capital</div>
                <div className="spacer_20margin_bottom">
                    <div className="si_su_emailmethod">
                        Sign In using your Email / Phone
                    </div>
                </div>

                <div className="spacer_15margin_bottom">
                    <div className="si_su_I_col">
                        <div className="si_su_Q">
                            Email or Phone
                            <div className="si_su_note">
                                <input
                                    type="checkbox"
                                    id="cb_remember"
                                    onClick={(e) => setRemember(e.target.checked)}
                                />
                                <label htmlFor="cb_remember">Remember Me</label>
                            </div>
                        </div>
                        <div className="signInTxtField">
                            <input
                                autoComplete={Helper.TimeStamp()}
                                type="text"
                                placeholder="Email / Mobile Number"
                                id="txtEmail"
                                name="txtEmail"
                                value={loginId}
                                onChange={(e) => OnLoginIdChanged(e)}
                            />
                        </div>
                    </div>
                </div>

                <div className="spacer_15margin_bottom">
                    {authType === 'mobile' && (
                        < ul className="si_I_col">
                            <li className="si_I_col">
                                <input
                                    type="button"
                                    value="Login with OTP"
                                    className="button_verify"
                                    onClick={(e) => OnGetOtpClicked(e)}
                                />
                            </li>
                        </ul>
                    )}
                    {authType !== 'mobile' && (
                        <ul className="si_II_col">
                            <li className="si_II_col">
                                <input
                                    type="button"
                                    value="Login with OTP"
                                    className="button_verify"
                                    onClick={(e) => OnGetOtpClicked(e)}
                                />
                            </li>
                            <li className="si_II_col">
                                <input
                                    type="button"
                                    value="Login with Password"
                                    className="button_verify"
                                    onClick={(e) => OnUsePasswordClicked(e)}
                                />
                            </li>
                        </ul>
                    )}
                </div>

                {otpSent && (
                    <ul className="si_su_II_col_40by60">
                        <li className="si_su_II_col_40by60">
                            <div className="signInTxtField">
                                <input
                                    maxLength={6}
                                    placeholder="OTP"
                                    type="password"
                                    onKeyPress={(e) => {
                                        return Helper.AcceptedChars(e, "NUMBER");
                                    }}
                                    onChange={(e) => OnOtpEntered(e)}
                                />
                            </div>
                        </li>
                        <li className="si_su_II_col_40by60">
                            <div className="si_su_OTP_note">
                                Please authenticate using the One Time Password sent to your email address
                            </div>
                        </li>
                    </ul>
                )}

                {showOtpResend && (
                    <div className="si_su_resendOTP">
                        <input type="button" value="Resend OTP"
                            className="button_si_su_resendOTP" onClick={(e) => OnGetOtpClicked(e)} />
                    </div>
                )}


                {usePassword && (
                    <ul className="si_II_col">
                        <li className="si_II_col">
                            <div className="signInTxtField">
                                <input
                                    placeholder="Password"
                                    type="password"
                                    onChange={(e) => OnPasswordEntered(e)}
                                />
                            </div>
                        </li>
                    </ul>
                )}

                {(usePassword || otpSent) && (
                    <div className="button_si_su_label">
                        <input
                            type="button"
                            value="SIGN IN"
                            onClick={(e) => OnSignInClicked(e)}
                            className="button_verify"
                        />
                    </div>
                )}
                {error && <div className="errormessage_center">{error}</div>}
                <div className="si_su_terms">
                    By signing in or creating an account, you're agreeing to our <Link to="/terms">Terms Of Use</Link> and our <Link to="/privacy">Security And Privacy Policy</Link>, which includes using arbitration to resolve claims related to the Telephone Consumer Protection Act.
                </div>
            </div>
        </>
    );

};

const Component = () => {
    const [reset, setReset] = useState(false);
    let closeElm = useRef(null);

    const OnClicked = (e) => {
        if (e === "hide") {
            closeElm.click();
        }
    };

    const OnCloseClicked = (e) => {
        e.preventDefault();
        window.fnSign(0);
        setReset(!reset);
    }

    return (
        <div className="si_su_Layout">
            <Link
                to="#"
                ref={(input) => (closeElm = input)}
                className="si_su_Close"
                onClick={(e) => OnCloseClicked(e)}
            ></Link>
            <div className="si_su_ContentArea">
                <SignInScreen reset={reset} onClicked={(e) => OnClicked(e)} />
            </div>
        </div>
    );
};

export default Component;
