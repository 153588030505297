import { React, Helper, useTimerSession, useLocation, Link, Container, useNavigate, PdfGenarator } from "common";
import { FinstaApi } from "services";

let summary = [
    { idName: 'I)', label: 'Total Instalment Receivable', type: 'IR', style: "LD_vII_summary_detail", field: "totalInstalmentReceivable" },
    { idName: 'II)', label: 'Instalment Received', type: 'I', style: "LD_vII_summary_detail", field: "instalmentReceived" },
    { idName: 'III)', label: 'Other Charges Adjusted', type: 'A', style: "LD_vII_summary_detail", field: "otherChargesAdjusted" },
    { idName: 'IV)', label: 'Instalment Due (I) - (II) + (III)', type: 'ID', style: "LD_vII_summary_detail_highlight", field: "instalmentDue", tag: true, },
    { idName: 'V)', label: 'Interest Receivable', style: "LD_vII_summary_detail", field: "interestReceivable" },
    { idName: 'VI)', label: 'Penalty Receivable', type: 'PR', style: "LD_vII_summary_detail", field: "penaltyReceivable" },
    { idName: 'VII)', label: 'Penalty Received', type: 'P', style: "LD_vII_summary_detail", field: "penaltyReceived" },
    { idName: 'VIII)', label: 'Penalty Waived Off', style: "LD_vII_summary_detail", field: "penaltyWaivedOff" },
    { idName: 'IX)', label: 'Penalty Due (VI) - ((VII) + (VIII))', type: "PD", style: "LD_vII_summary_detail_highlight", field: "penaltyDue", tag: true },
    { idName: 'X)', label: 'Interest Waived Off', style: "LD_vII_summary_detail", field: "interestWaivedOff" },
    { idName: 'XI)', label: 'Balance (IV) + (IX) - (X)', type: 'B', style: "LD_vII_summary_detail_highlight", field: "balance", tag: true }
]


const RenderSummary = ({ loanAccounts }) => {

    const loanInfo = loanAccounts?.loaninfo || {};

    let dt = new Date();
    const month = dt.getMonth();
    dt = new Date(dt.getFullYear(), month, 1);
    dt = new Date(new Date(dt) - 1);

    return (
        <>
            <div className="LD_HeaderBlk">Loan Summary as on {Helper.ToDateFormat(dt, 'YYYY-MM-DD')}</div>
            <ul className="LD_vI_summary">
                <li className="LD_vI_summary">

                    {summary.map((x, index) => (
                        <ul key={index} className={x.style}>
                            <li className="LD_vII_summary_number">
                                <div className="LD_textBold">{x.idName}</div>
                            </li>
                            <li className="LD_vII_summary_label">
                                <div className="LD_textBold">{x.label}</div>
                            </li>
                            <li className="LD_vII_summary_margin">
                                <div className="LD_textBold">=</div>
                            </li>
                            <li className="LD_vII_summary_value">
                                {
                                    !Helper.IsNullValue(loanInfo[x.field])
                                        ? Helper.INRCurrencyFormat(
                                            loanInfo[x.field] < 0 ? -loanInfo[x.field] : loanInfo[x.field],
                                            2
                                        ) + (x.tag ? (loanInfo[x.field] < 0 ? ' Cr' : ' Dr') : '')
                                        : 'NA'
                                }
                            </li>
                        </ul>
                    ))}
                </li>
            </ul>
        </>
    )

}

const Component = () => {

    const [initlized, setInitlized] = React.useState(false);
    const [showBorrower, setShowBorrower] = React.useState(false);
    const [loanAccounts, setLoanAccounts] = React.useState([]);
    const [loanTrans, setLoanTrans] = React.useState([]);
    const [loanNumber] = useTimerSession('loanNumber');
    const NavigateTo = useNavigate();
    const { isClosed } = useLocation().state || false;

    const FetchResults = async () => {
        global.Busy(true);
        let rslt = await FinstaApi.GetAccountDetails(loanNumber);
        global.Busy(false);
        if (rslt.status === 100) {
            let tmp = Helper.SortBy(rslt.data.transInfo, "transDate");
            setLoanTrans(tmp);
            setLoanAccounts(rslt.data);
        }
    };

    if (initlized) {
        setInitlized(false);
        setLoanAccounts([]);
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    const GetGender = (v) => {
        if (v === 'M' || v === 'm') return "Male";
        if (v === 'F' || v === 'f') return "Female";
        return v;
    }

    const OnShowBorrower = (e, bBool) => {
        e.preventDefault();
        setShowBorrower(bBool);
    }

    const getAccountDetails = async () => {
        try {
            global.Busy(true)
            const pdfname = loanAccounts.loaninfo.loanNumber
            const pdfData = await PdfGenarator.pdfGenerator(loanAccounts);
            const pdfBuffer = await new Promise((resolve, reject) => {
                pdfData.getBuffer((buffer) => {
                    resolve(buffer);
                });
            });
            const blob = new Blob([pdfBuffer], { type: 'application/pdf' });

            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = `${pdfname}.pdf`;
            link.click();
            URL.revokeObjectURL(url);
        } catch (error) {
            global.Busy(false)
            console.error('Error:', error);
        }
        global.Busy(false);
    }

    return (
        <>
            <Container>
                <div id="container">
                    <div id="content">
                        <ul className="bc_inPage">
                            <div className="widthfix_10px">
                                <li className="bc_inPage"><Link to="/cd">Dashboard</Link></li>
                                {isClosed && (<li className="bc_inPage"><Link to="/ccl">Closed Loan Account Summary</Link></li>)}
                                {!isClosed && (<li className="bc_inPage"><Link to="/cl">Loan Account Summary</Link></li>)}
                                <li className="bc_inPage">Loan ID - {loanAccounts?.loaninfo?.loanNumber}</li>
                            </div>
                        </ul>
                        <div className="widthfix_10px">
                            <div className="loanListDetails">
                                <div className="loanlistContentBlk">
                                    <div className="loanlistHeadBlk">
                                        <div className="blkName">Loan ID - {loanAccounts?.loaninfo?.loanNumber}</div>

                                        <div className="head_right">
                                            <input type="button" className="rightButton" value="Download Statement" onClick={getAccountDetails} />
                                            <div className="rightVerBlock">
                                                <input type="button" className="rightButton" value="&#8810; Back" onClick={(e) => {
                                                    e.preventDefault();
                                                    if (isClosed) {
                                                        NavigateTo("/ccl");
                                                    } else {
                                                        NavigateTo("/cl");
                                                    }
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="LD_Blk">
                                        <ul className="LD_Summary">
                                            <li className="LD_Summary">
                                                <div className="LD_summaryTextNormal">Sanctioned Amount:</div>
                                                <div className="LD_summaryTextBold">{Helper.INRCurrencyFormat(loanAccounts?.loaninfo?.loanAmount, true)}</div>
                                            </li>
                                            <li className="LD_Summary">
                                                <div className="LD_summaryTextNormal">Balance:</div>
                                                <div className="LD_summaryTextBold">{Helper.INRCurrencyFormat(loanAccounts?.loaninfo?.balance, true)}</div>
                                            </li>
                                            <li className="LD_Summary">
                                                <div className="LD_summaryTextNormal">EMI Pending:</div>
                                                <div className="LD_summaryTextBold">{(loanAccounts?.loaninfo?.tenure || 0) - (loanAccounts?.loaninfo?.numEmiPaid || 0)}</div>
                                            </li>
                                            <li className="LD_Summary">
                                                <div className="LD_summaryTextNormal">Interest Rate:</div>
                                                <div className="LD_summaryTextBold">{Helper.ToFixed(loanAccounts?.loaninfo?.rateOfInterest, 2)}%</div>
                                            </li>
                                            <li className="LD_Summary">
                                                <div className="LD_summaryTextNormal">Loan Type:</div>
                                                <div className="LD_summaryTextBold">{loanAccounts?.loaninfo?.loanType}</div>
                                            </li>
                                            <li className="LD_Summary">
                                                <div className="LD_summaryTextNormal">Frequency:</div>
                                                <div className="LD_summaryTextBold">{loanAccounts?.loaninfo?.frequency}</div>
                                            </li>
                                            <li className="LD_Summary">
                                                <div className="LD_summaryTextNormal">Next Payment Due:</div>
                                                <div className="LD_summaryTextBold">{Helper.ToDate(loanAccounts?.loaninfo?.nextEmiDate, "YYYY-MM-DD")}</div>
                                            </li>
                                            <li className="LD_Summary">
                                                <div className="LD_summaryTextNormal">Scheduled Payments (Term):</div>
                                                <div className="LD_summaryTextBold">{loanAccounts?.loaninfo?.tenure} EMI of {Helper.INRCurrencyFormat(loanAccounts?.loaninfo?.emi, true)}</div>
                                            </li>
                                        </ul>
                                        <div className="loanlistHeadBlk">
                                            <div className="blkSubName">
                                                {!showBorrower && <Link to="#" onClick={(e) => OnShowBorrower(e, true)} >Show Borrower Details</Link>}
                                                {showBorrower && <Link to="#" onClick={(e) => OnShowBorrower(e, false)}>Hide Borrower Details</Link>}
                                            </div>
                                        </div>
                                        {showBorrower && (
                                            <ul className="LD_vI_Blk">
                                                <li className="LD_vI_Blk">
                                                    <div className="LD_HeaderBlk">Borrower Details</div>
                                                    <ul className="LD_vII_content">
                                                        <li className="LD_vII_content">
                                                            <div className="LD_textBold">Borrower Id:</div>
                                                            <div className="LD_textNormal">{loanAccounts?.userInfo?.custId}</div>
                                                        </li>
                                                        <li className="LD_vII_content">
                                                            <div className="LD_textBold">Name:</div>
                                                            <div className="LD_textNormal">{loanAccounts?.userInfo?.borrowerName}</div>
                                                        </li>
                                                        <li className="LD_vII_content">
                                                            <div className="LD_textBold">Date Of Birth:</div>
                                                            <div className="LD_textNormal">{Helper.ToDate(loanAccounts?.userInfo?.dateOfBirth, "YYYY-MM-DD")}</div>
                                                        </li>
                                                        <li className="LD_vII_content">
                                                            <div className="LD_textBold">Gender:</div>
                                                            <div className="LD_textNormal">{GetGender(loanAccounts?.userInfo?.gender)}</div>
                                                        </li>
                                                        <li className="LD_vII_content">
                                                            <div className="LD_textBold">Phone Number:</div>
                                                            <div className="LD_textNormal">{loanAccounts?.userInfo?.phoneNo}</div>
                                                        </li>
                                                        <li className="LD_vII_content">
                                                            <div className="LD_textBold">Pin Code:</div>
                                                            <div className="LD_textNormal">{loanAccounts?.userInfo?.pincode}</div>
                                                        </li>
                                                        <li className="LD_vII_content">
                                                            <div className="LD_textBold">Father Name:</div>
                                                            <div className="LD_textNormal">{loanAccounts?.userInfo?.fatherName}</div>
                                                        </li>
                                                        <li className="LD_vI_content">
                                                            <div className="LD_textBold">Address:</div>
                                                            <div className="LD_textNormal">{loanAccounts?.userInfo?.address}</div>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        )}
                                        <ul className="LD_vII_Blk">
                                            <li className="LD_vII_Blk">
                                                <RenderSummary loanAccounts={loanAccounts} />
                                            </li>
                                            <li className="LD_vII_Blk">
                                                <div className="LD_HeaderBlk">Loan Payment Details</div>
                                                <div className="LD_TransactionTable_head">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>Trans Date</td>
                                                                <td>Trans No</td>
                                                                <td>Particulars</td>
                                                                <td>Debit</td>
                                                                <td>Credit</td>
                                                                <td>Balance</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="LD_TransactionTable">
                                                    <table>
                                                        <tbody>

                                                            {!loanAccounts?.transInfo || loanAccounts?.transInfo.length == 0 && (
                                                                <tr>
                                                                    <td colSpan={8}>
                                                                        <div className="noContent">No transactions details are exist</div>
                                                                    </td>
                                                                </tr>
                                                            )}

                                                            {loanAccounts?.transInfo && loanAccounts?.transInfo.length > 0 &&
                                                                loanTrans.map((x, index) => (
                                                                    <tr key={index}>
                                                                        <td>{Helper.ToDateFormat(x.transDate, "YYYY-MM-DD")}</td>
                                                                        <td>{Helper.ToString(x.transNo)}</td>
                                                                        <td>{Helper.ToString(x.particulars)}</td>

                                                                        {x.debit && parseFloat(x.debit) > 0 ? (
                                                                            <td>{Helper.INRCurrencyFormat(x.debit, 2)}</td>
                                                                        ) : (
                                                                            <td>&nbsp;</td>
                                                                        )}

                                                                        {x.credit && parseFloat(x.credit) > 0 ? (
                                                                            <td>{Helper.INRCurrencyFormat(x.credit, 2)}</td>
                                                                        ) : (
                                                                            <td>&nbsp;</td>
                                                                        )}
                                                                        <td>{x.balance}</td>
                                                                    </tr>
                                                                ))
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
}

export default Component;