import {
  React, Link, useTimerSession, useNavigate, useLocation,
  useEffect, useState, Session
} from "common";
import Authentication from "../popups/auth";
import PopupDialog from "../popups/popdlg";
import ConfirmDialog from "../popups/confirmdlg";
import ChangePassword from "../popups/changePassword";
import AlertDialog from "../popups/alertdlg";
import CreditScore from "../popups/creditScore";

import DialogContainer from "../popups/dialog";

// Loans
const mnu1 = ["/gloans", "/bikeloan", "/goldloan", "/consumerloan", "/propertyloan", "/businessloan", "/apply", "/goldloanreview"];
// Pre Approval
const mnu2 = ["/preapproval"];
// Instant Approval
const mnu3 = ["/emi"];
// Fair Practice Code
const mnu4 = ["/eligibility",];
// More
const mnu5 = ["/about", "/career", "/fairpractice", "/contact", "/management", "/chargesbusiness", "/chargesbike", "/chargesgold"];

const mnu6 = ["/cd", "/cl", "/cld"];

const LoggedOutHeader = (props) => {
  const { loc, onDialogType } = props;

  const IsActive = (mnu) => {
    return mnu.findIndex((x) => x === loc) > -1 ? "dropbtn active" : "dropbtn";
  };

  const OnCreditScoreClicked = async (e) => {
    e.preventDefault();
    onDialogType('CREDTISCORE');
  };

  return (
    <>
      <header className="header">
        <div className="widthfix">
          <div className="logo">
            <Link to="#">
              <div className="mcplLogo logoPositionHome"></div>
            </Link>
          </div>
          <div className="topRight_fixed_blk">
            <div className="widthfix">
              <div className="headerTopRight">
                <ul className="header_email_blk">
                  <li className="header_mail_icon"></li>
                  <li className="call_no_blk">
                    <div className="callus">Mail us -</div>
                    <div className="phNo">info@mohamcapital.com</div>
                  </li>
                </ul>

                <ul className="header_phone_blk">
                  <li className="header_ph_icon"></li>
                  <li className="call_no_blk">
                    <div className="callus">Call us -</div>
                    <div className="phNo">(080) 26496555</div>
                  </li>
                </ul>

                <ul className="header_Account_blk">
                  <li className="header_profile_img"></li>
                  <li className="header_nameAcc_blk">
                    <div className="headerProfileName">Hello, Sign In</div>
                    <div className="headerAccount">
                      <div className="accountnav" id="myAccountnav">
                        <div className="dropdownAcc">
                          <button className="dropbtnAcc">
                            Account <div className="arrow_down"></div>
                          </button>
                          <div className="dropdown-content">
                            <Link
                              to="#"
                              className="icon"
                              onClick={() => window.fnSign(1)}
                            >
                              <div className="loginIcon"></div>Login / Signup
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>

                <div className="getStarted_btnLabel">
                  <Link to="/preapproval"><input type="button" className="getStarted_button" value="Get Started" /></Link>
                </div>

                {/* <div className="header_Ph_blk">
                  <div className="callus">Call us at</div>
                  <div className="phNo">(080) 26492353</div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="navBlock">
          <div className="widthfix">
            <div className="headerRightBlock">
              <ul className="supportNavi">
                <li className="supportNavi">
                  <Link to="#" title="Check your Credit Score" onClick={(e) => OnCreditScoreClicked(e)}>
                    <div className="becomeBranchIcon"></div>
                    <div className="supportNaviText">Free Credit Score</div>
                  </Link>
                </li>
                <li className="supportNavi">
                  <Link to="/branch" title="Nearest Branch">
                    <div className="findOfficerIcon"></div>
                    <div className="supportNaviText">Branch Locator</div>
                  </Link>
                </li>
              </ul>
            </div>

            <div className="topnav">
              <div className="dropdown">
                <Link to="/gloans"><button className={IsActive(mnu1)}>
                  <div className="loanIcon"></div>
                  Loans <div className="arrow_down_topnav"></div>
                </button></Link>
                <div className="dropdown-content">
                  <Link to="/bikeloan">Two Wheeler Loan</Link>
                  <Link to="/goldloan">Gold Loan</Link>
                  <Link to="/consumerloan">Consumer Durables Loan</Link>
                  <Link to="/propertyloan">Loan Against Property</Link>
                  <Link to="/businessloan">Business Loan</Link>
                </div>
              </div>
              <div className="dropdown">
                <Link to="/preapproval"><button className={IsActive(mnu2)}>
                  <div className="preApprovalIcon"></div>
                  Pre-Approval
                </button></Link>
              </div>
              <div className="dropdown">
                <Link to="/emi"><button className={IsActive(mnu3)}>
                  <div className="emiCalcIcon"></div>
                  EMI Calculator
                </button></Link>
              </div>

              <div className="dropdown">
                <Link to="/eligibility"><button className={IsActive(mnu4)}>
                  <div className="loanEligibilityIcon"></div>
                  Loan Eligibility
                </button></Link>
              </div>
              <div className="dropdown">
                <button className={IsActive(mnu5)}>
                  <div className="moreIcon"></div>
                  More <div className="line_topnav"></div>
                </button>
                <div className="dropdown-content">
                  <Link to="/about">About Us</Link>
                  <Link to="/career">Careers</Link>
                  <Link to="/fairpractice">Fair Practice Code</Link>
                  <Link to="/contact">Contact Us</Link>
                  <Link to="/chargesbusiness">Charges</Link>
                  <Link to="/agent">Agent Program</Link>
                  <Link to="/refund">Refund Policy</Link>
                  <Link to="/terms">Terms of Use</Link>
                </div>
              </div>
              {/* <Link to="#" className="icon" onClick={() => window.myFunction()}></Link> */}
            </div>
          </div>
        </div>
      </header>

      <header className="headerResponsive">
        <div className="headerTopRightResponsive">
          <ul className="header_Account_blk_responsive">
            <li className="header_profile_img_responsive"></li>
            <li className="header_nameAcc_blk_responsive">
              <div className="headerProfileName_mini">Hello, Sign In</div>
              <div className="headerAccount_mini">
                <div className="accountnav_mini" id="myAccountnav">
                  <div className="dropdownAcc_mini">
                    <button className="dropbtnAcc_mini">
                      Account <div className="arrow_down_mini"></div>
                    </button>
                    <div className="dropdown-content_mini">
                      <Link
                        to="#"
                        className="icon"
                        onClick={() => window.fnSign(1)}
                      >
                        <div className="loginIcon"></div>Login / Signup
                      </Link>
                    </div>
                  </div>
                  <Link to="#" className="icon"></Link>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div className="navBlockResponsive">
          <div className="topnav" id="myTopnav">
            <Link to="#">
              <div className="logoPosResponsive mcplLogo"></div>
            </Link>
            <div className="dropdown">
              <button className={IsActive(mnu1)}>
                Loans <div className="arrow_down_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/bikeloan">Two Wheeler Loan</Link>
                <Link to="/goldloan">Gold Loan</Link>
                <Link to="/consumerloan">Consumer Durables Loan</Link>
                <Link to="/propertyloan">Loan Against Property</Link>
                <Link to="/businessloan">Business Loan</Link>
              </div>
            </div>
            <div className="dropdown">
              <button className={IsActive(mnu2)}>
                Pre-Approval <div className="arrow_down_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/preapproval">Check Pre-Approval Offer</Link>
              </div>
            </div>
            <div className="dropdown">
              <button className={IsActive(mnu3)}>
                EMI Calculator <div className="arrow_down_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/emi">Calculator your EMI</Link>
              </div>
            </div>
            <div className="dropdown">
              <button className={IsActive(mnu4)}>
                Loan Eligibility <div className="arrow_down_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/eligibility">Check Loan Eligibility</Link>
              </div>
            </div>
            <div className="dropdown">
              <button className={IsActive(mnu5)}>
                More <div className="line_topnav"></div>
              </button>
              <div className="dropdown-content">
                <Link to="/about">About Us</Link>
                <Link to="/career">Careers</Link>
                <Link to="/fairpractice">Fair Practice Code</Link>
                <Link to="/contact">Contact Us</Link>
                <Link to="/chargesbusiness">Charges</Link>
                <Link to="/agent">Agent Program</Link>
                <Link to="/refund">Refund Policy</Link>
                <Link to="/terms">Terms of Use</Link>
              </div>
            </div>
            <Link
              to="#"
              className="icon"
              onClick={() => window.myFunction()}
            ></Link>
          </div>
        </div>
      </header>
    </>
  );
};

const LoggedInHeader = (props) => {
  const { loc } = props;
  let user = Session.RetrieveJSON("user");
  const [fullName, setFullName] = useState("Hello, Sign In");
  const [custId, setCustId] = useState("");
  const NavigateTo = useNavigate();

  const changePassword = async (e) => {
    e.preventDefault();
    document.getElementById("changepswd_pop").style.display = "block";
  };

  useEffect(() => {
    if (user) {
      setFullName(`Hello, ${user?.name}`);
      setCustId(user?.custId);
    };
  }, [user]);

  const OnSignOutClicked = async (e) => {
    e.preventDefault();
    Session.Remove("user");
    Session.Remove("token");
    Session.Remove("statusid");
    NavigateTo("/");
  };

  const IsActive = (mnu, dropIcon) => {
    if (dropIcon) return mnu.findIndex((x) => x === loc) > -1 ? `${dropIcon} active` : dropIcon;
    return mnu.findIndex((x) => x === loc) > -1 ? "dropbtn active" : "dropbtn";
  };

  return (
    <>
      <header id="headerInpage">
        <div className="widthfix">
          <div className="navRight">
            <Link to="#" title="Settings"><div className="settingicon"></div></Link>
            <Link to="#" title="Tasks"><div className="alerticon"></div></Link>
            <ul className="rightAccountBlk">
              <li className="rightAccountBlk">{fullName}</li>
              <li className="rightAccountBlk">
                <div className="accountnav_mini" id="myAccountnav">
                  <div className="dropdownAcc_mini">
                    <button className="dropbtnAcc_mini">Cust Id: {custId} <div className="arrow_down_mini"></div></button>
                    <div className="dropdown-content_mini">
                      <Link to="/pf" ><div className="profileIcon"></div>Profile</Link>
                      <Link to="#" onClick={(e) => OnSignOutClicked(e)}><div className="logoutIcon"></div>Logout</Link>
                    </div>
                  </div>
                  <Link to="#" className="icon"></Link>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="navBlockPostLogin">
          <div className="widthfix">
            <div className="topnav" id="myTopnav">
              <Link to="#"><div className="logoPosResponsive mcplLogoWhite"></div></Link>

              <div className="dropdown">
                <Link to="/cd"><button className={IsActive(mnu6)}><div className={IsActive(mnu6, "dashboardIcon")}></div>Dashboard</button></Link>
              </div>

              <div className="dropdown">
                <Link to="/apply"><button className={IsActive(mnu1)}><div className={IsActive(mnu1, "loanApplyIcon")}></div>Apply for Loan</button></Link>
              </div>

              <div className="dropdown">
                <Link to="/preapproval"><button className={IsActive(mnu2)}><div className={IsActive(mnu2, "preApprovalIcon")}></div>Pre-Approval</button></Link>
              </div>

              <div className="dropdown">
                <Link to="/emi"><button className={IsActive(mnu3)}><div className={IsActive(mnu3, "EMICalcIcon")}></div>EMI Calculator</button></Link>
              </div>

              <div className="dropdown">
                <button className={IsActive(mnu5)}><div className={IsActive(mnu5, "moreIcon")}></div>More <div className="arrow_down_topnav"></div></button>
                <div className="dropdown-content">
                  <Link to="/about">About Us</Link>
                  <Link to="/career">Careers</Link>
                  <Link to="/fairpractice">Fair Practice Code</Link>
                  <Link to="/contact">Contact Us</Link>
                  <Link to="/chargesbusiness">Charges</Link>
                  <Link to="/agent">Agent Program</Link>
                  <Link to="/refund">Refund Policy</Link>
                  <Link to="/terms">Terms of Use</Link>
                </div>
              </div>

              <Link to="#" className="icon" onClick={() => window.myFunction()}></Link>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

const Component = (props) => {
  const [token] = useTimerSession('token');
  const loc = useLocation().pathname;

  const [dialogName, setDialogName] = React.useState(null);
  const [dialogShow, setDialogShow] = React.useState(false);

  useEffect(() => {
    if (loc !== '/apply') global.RemoveLoanTypeSelection();
  }, [loc]);

  const OnDialogType = (e) => {
    setDialogName(e);
    setDialogShow(true);
  }

  const OnDialogClose = (e) => {
    setDialogName(null);
    setDialogShow(false);
  }

  return (
    <>
      {token ? (
        <LoggedInHeader loc={loc} {...props} onDialogType={OnDialogType} />
      ) : (
        <LoggedOutHeader loc={loc} {...props} onDialogType={OnDialogType} />
      )}

      <DialogContainer show={dialogShow} type={dialogName} OnCloseClick={OnDialogClose} />

      <Authentication />
      <PopupDialog />
      <ChangePassword />
      <AlertDialog />
      <ConfirmDialog title={"Do you want to continue with your Pre-approval?"} message={"You'll be able to pick from where you left"} display={false} />
    </>
  );
};

export default Component;
