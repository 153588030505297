import { Helper, Link, React, useRef } from "common";
import { CommonApi } from "services";

const Component = (props) => {

    const { show, onOTPStatus, phoneNumber, dlgState } = props;
    const [initlized, setInitlized] = React.useState(false);
    const [error, setError] = React.useState(null);

    const [keyId, setKeyId] = React.useState(null);
    const [submitDisabled, setSubmitDisabled] = React.useState(true);

    const display = show ? "block" : "none";
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    const OnCancelClicked = (e) => {
        e.preventDefault();
        if (onOTPStatus) onOTPStatus(false);
    }

    const OnSubmitClicked = async (e) => {
        if (e) e.preventDefault();

        const pinNumber = inputRefs.map((x) => x.current.value).join("");
        let keyId = Helper.CleanPhoneNumber(phoneNumber);

        global.Busy(true);
        let rslt = await CommonApi.ValidateOtp("mobile", keyId, parseInt(pinNumber));
        global.Busy(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        }
        if (onOTPStatus) onOTPStatus(true);
    }

    const onHandleChange = (e, index) => {
        setError(null);
        const { value } = e.target;
        if (value.length > 0 && index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }

        const refCount = inputRefs
            .filter((z) => !Helper.IsNullValue(z.current.value))
            .map((x) => x.current.value);

        if (refCount && refCount.length === 4) {
            setSubmitDisabled(false);
            OnSubmitClicked();
        } else {
            setSubmitDisabled(true);
        }
    };

    const OnSendOTP = async (e) => {

        setError(null);

        if (Helper.IsNullValue(phoneNumber)) return;

        let _phoneNumber = Helper.CleanPhoneNumber(phoneNumber);
        let _input = { phone: _phoneNumber };

        if (e) {
            e.preventDefault();
            _input._id = keyId;
        }

        global.Busy(true);
        let rslt = await CommonApi.GetOtp('mobile', _input.phone);
        global.Busy(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        } else {
            setKeyId(rslt.data);
        }
        global.Busy(true);
        setTimeout(() => {
            global.Busy(false);
        }, 5000);
    }

    if (initlized) {
        setInitlized(false);
        setError(null);
        inputRefs.map((x) => x.current.value = "");
        OnSendOTP();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, [dlgState]);

    return (
        <div id="OTPVerify_pop" className="pop_disable_bg" style={{ display }}>
            <div className="otpVerify_pop">
                <div className="otpVerify_ContentArea">
                    <div className="otpVerify_Head">OTP Verification</div>
                    <div className="otpVerify_descText">Enter the OTP you received to:</div>
                    <div className="otpVerifyUpdatePh"><Link to="#" onClick={(e) => OnCancelClicked(e)}>Change Phone</Link></div>
                    <div className="otpVerify_mobileNo">+91 {phoneNumber}</div>
                    <ul className="otpVerify_4col">
                        {inputRefs.map((ref, index) => (
                            <li className="otpVerify_4col" key={index}>
                                <div className="pop_otpVerifyTxtField">
                                    <input
                                        type="text"
                                        maxLength="1"
                                        ref={ref}
                                        onChange={(event) => onHandleChange(event, index)}
                                        onKeyPress={(e) => {
                                            return Helper.AcceptedChars(e, 'NUMBERS');
                                        }}
                                    />
                                </div>
                            </li>
                        ))}
                    </ul>
                    <div className="otpVerifyResend"><Link to="#" onClick={(e) => OnSendOTP(e)}>Resend otp</Link></div>
                    <div className="popOtpVerify_Submit_Button_Layout">
                        <input type="button" className="popOtpVerify_Submit_Button" value="Continue"
                            disabled={submitDisabled}
                            onClick={(e) => OnSubmitClicked(e)} />
                        <input type="button" className="popOtpVerify_Submit_Button" value="Cancel" onClick={(e) => OnCancelClicked(e)} />
                    </div>
                    {error && <div className="errormessage_center">{error}</div>}
                </div>
            </div>
        </div>
    );
};

export default Component;