import { React, Link, Container, Session, useNavigate } from "common";

const Component = () => {

    const Navigate = useNavigate();

    const OnLoanTypeSelected = (e, type, name) => {
        e.preventDefault();
        Session.Store('loanTypeId', type);
        Session.Store('loanTypeName', name);
        Navigate("/apply");
    }

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_property">
                            <div className="inPgBcShadeBlk">
                                <div className="inPgBcCrossBlk">
                                    <div className="widthfix_10px">
                                        <ul className="bc_IIcol">
                                            <li className="bc_IIcol">
                                                <div className="inPgBcHalfBlk">
                                                    <div className="inPgBcTextBlk">
                                                        <Link to="/loans"><div className="bcMainTxt">Loans</div></Link>
                                                        <div className="bcSubTxt">/ Loan Against Property</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="bc_IIcol">
                                                <ul className="bcPointsSlanting">
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Avail High Loan Amount at Low Interest Rate</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Repay in Comfortable EMIs with Longer Tenure</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Simple and Hassle Free Solution to your Financial Needs</li>
                                                </ul>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="loanInpg">
                            <div className="widthfix_10px">
                                <ul className="loanIIcolContainer">
                                    <li className="loanIIcolContainer">
                                        <div className="loanImgBlk">
                                            <div className="propertyImage"></div>
                                        </div>
                                    </li>
                                    <li className="loanIIcolContainer">
                                        <div className="loanContentBlk">
                                            <div className="loan_headTxt">Leverage your <span className="headTxtRed"> property's value</span></div>
                                            <div className="loan_heroTxt">Unlock the Property Value with a Loan Against It</div>
                                            <ul className="loan_uspPoint">
                                                <li className="loan_uspPoint">Flexible Repayment Options</li>
                                                <li className="loan_uspPoint">Streamlined Application Process</li>
                                                <li className="loan_uspPoint">Competitive Interest Rates</li>
                                                <li className="loan_uspPoint">No Hidden Fees or Charges</li>
                                            </ul>
                                            <Link to="/apply" onClick={(e) => OnLoanTypeSelected(e, 3, "Loan Against Property")}><input type="button" className="loan_usp_button" value="Apply Now" /></Link>
                                        </div>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div >
                </div >
            </Container>
        </>
    )
}

export default Component;