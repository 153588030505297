import React from "react";
import { Helper } from "helper";

const Component = (props) => {
    const { src, alt, top } = props;

    return (
        <>
            <img src={`${src}?v=${Helper.TimeStamp()}`} alt={alt} style={{ top }} />
        </>
    );
}

export default Component;
