import { React } from "react-router-dom";

const Component = () => {
    return (
        <div>
            <div className="applyEasySteps">
                <div className="applyStepHead">
                    Get Your Loan in 3 Easy Steps
                </div>
                <ul className="applySteps">
                    <li className="applySteps">
                        <div className="applyEasyIcon"></div>
                    </li>
                    <li className="applySteps">
                        Simple Verification Process
                    </li>
                </ul>
                <ul className="applySteps">
                    <li className="applySteps">
                        <div className="applyFastIcon"></div>
                    </li>
                    <li className="applySteps">
                        Instant Loan Approvals
                    </li>
                </ul>
                <ul className="applySteps">
                    <li className="applySteps">
                        <div className="applyCashIcon"></div>
                    </li>
                    <li className="applySteps">
                        Get Your Cash
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Component;