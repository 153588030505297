import API from "./api";

const IsexistAgent = async (input) => {
    try {
        const res = await API.post(`users/isexistagent`, input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const AddAgent = async (input) => {
    try {
        const res = await API.post(`users/agent`, input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};


const sentAgentEmail = async (input) => {
    try {
        const res = await API.post(`/users/sentagentemail`, input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};
//const validationUrl = "http://localhost:4000/";
const validationUrl = "https://extvalid.mohamcapital.in/";


const GetAgentAadhaarOtp = async (input) => {
    return new Promise(async (resolve) => {

        let url = `${validationUrl}external/validation/aadharotp`;

        // const token = await Session.GetHeader();

        try {
            const res = await fetch(url, {
                method: "POST",
                redirect: 'follow',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(input)
            });

            const json = await res.json();
            if (res.status === 200) {
                return resolve(json);
            }

            return resolve({ status: 900, statusText: json.error.message });

        } catch (error) {
            return resolve({ status: 900, statusText: "Failed connecting to server" });
        }
    })
}

// const GetAgentAadhaarOtp = async (input) => {
//     try {
//         const res = await API.post(`/users/agentaadharotp`, input);
//         return res.data;
//     } catch (err) {
//         return { statusText: err.message, status: 900 };
//     }
// };

const AgentAadhaarVerify = async (input) => {
    return new Promise(async (resolve) => {
        let url = `${validationUrl}external/validation/verifyaadhar`;

        // const token = await Session.GetHeader();

        try {
            const res = await fetch(url, {
                method: "POST",
                redirect: 'follow',
                // headers: token,
                headers: { "Content-Type": "application/json" },

                body: JSON.stringify(input)
            });

            const json = await res.json();
            if (res.status === 200) {
                return resolve(json);
            }

            return resolve({ status: 900, statusText: json.error.message });

        } catch (error) {
            return resolve({ status: 900, statusText: "Failed connecting to server" });
        }
    })
    // try {
    //     const res = await API.post(`external/validation/verifyaadhar`, input);
    //     return res.data;
    // } catch (err) {
    //     return { statusText: err.message, status: 900 };
    // }
};

const IsAadhaarValid = async (input) => {
    console.log(input)
    return new Promise(async (resolve) => {
        let url = `${validationUrl}external/validation/isvalidaadhar`;

        // const token = await Session.GetHeader();

        try {
            const res = await fetch(url, {
                method: "POST",
                redirect: 'follow',
                // headers: token,
                headers: { "Content-Type": "application/json" },

                body: JSON.stringify(input)
            });

            const json = await res.json();
            if (res.status === 200) {
                return resolve(json);
            }

            return resolve({ status: 900, statusText: json.error.message });

        } catch (error) {
            return resolve({ status: 900, statusText: "Failed connecting to server" });
        }
    })
    // try {
    //     const res = await API.post(`external/validation/isvalidaadhar`, input);
    //     return res.data;
    // } catch (err) {
    //     return { statusText: err.message, status: 900 };
    // }
};

const AddCustomer = async (input) => {
    try {
        const res = await API.post(`users/customer`, input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

export { AddAgent, GetAgentAadhaarOtp, AgentAadhaarVerify, AddCustomer, IsAadhaarValid, IsexistAgent, sentAgentEmail };
