import React from "react";
import Header from "../headers";
import Footer from "../footers";

const Component = ({ children, nofooter }) => {
    return (
        <>
            <Header />
            {children}
            {!nofooter && <Footer />}
        </>
    );
};

export default Component;