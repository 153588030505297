import React, { useEffect, useRef, useState } from "react";
import Slider from 'react-slick';
import { Link, useNavigate, useLocation, useSearchParams, useParams } from "react-router-dom";
import {
    TextInput, RadioInput, DropDown, CheckInput, FileInput,
    OptionInput, ViewPdf, DatePicker, DoughnutChart, Image,
    CustomButton
} from "./components";

import {
    Headers, Footers, Container,
    ThreeSteps, Advantage, LoanTypeSelection, ApplyJobForm
} from "./screens/shared";

import { Storage, useTimerSession, UseTimerStore, Helper, UploadHelper, Session, Support, PdfGenarator } from "./helper";

const PDFGeneratorApi = "https://pdf.mohamcapital.com";

import "./content/style/header.scss";
import "./content/style/footer.scss";
import "./content/style/home.scss";
import "./content/style/inpage.scss";
import "./content/style/common.scss";
import "./content/style/pop.scss";
import "./content/style/slick.scss";
import "./content/style/slick-theme.scss";
import "./content/style/react-datepicker.css";

//const StateLookUps = Object.values(stateData);

export {
    React, useEffect, useRef, useState,
    Link, useNavigate, useLocation, useSearchParams, useParams,
    TextInput, RadioInput, DropDown, CheckInput, OptionInput, ViewPdf, DatePicker, DoughnutChart, Image, PdfGenarator, CustomButton,
    Slider, Headers, Footers, Container, FileInput,
    ThreeSteps, Advantage, LoanTypeSelection, ApplyJobForm,
    Storage, useTimerSession, UseTimerStore, Helper, UploadHelper, Session, Support, PDFGeneratorApi
};
