import { React } from "react-router-dom";

const Component = () => {
    return (
        <div>
            <div className="advMC">
                <div className="advMCHead">
                    Moham Capital Advantage
                </div>
                <ul className="advMCPoints">
                    <li className="advMCPoints">
                        Zero Down Payment
                    </li>
                    <li className="advMCPoints">
                        High Loan Amount
                    </li>
                    <li className="advMCPoints">
                        Easy Repayment Period
                    </li>
                    <li className="advMCPoints">
                        Fast Application Process
                    </li>
                    <li className="advMCPoints">
                        Competitive Interest Rate
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Component;