import { React, Link, Helper, Container, useState, useTimerSession } from "common";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie as PieChart } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

const _defaChart = {
    labels: ['Principal', 'Interest'],
    datasets: []
}

const Component = () => {
    const [loanAmount, setLoanAmount] = useState('');
    const [interestRate, setInterestRate] = useState('');
    const [loanTenure, setLoanTenure] = useState('');
    const [emi, setEmi] = useState('');
    const [totalInterest, setTotalInterest] = useState('');
    const [totalPayment, setTotalPayment] = useState('');
    const [chartInfo, setChartInfo] = useState(_defaChart);
    const [token] = useTimerSession('token');

    const contentId = token ? 'content' : 'content_fullHeader';

    const ResetValues = () => {
        setEmi('');
        setTotalInterest('');
        setTotalPayment('');
        setChartInfo(_defaChart);
    };

    const calculateEmi = () => {
        if (loanAmount && interestRate && loanTenure) {
            const principle = parseFloat(loanAmount);
            const rate = parseFloat(interestRate) / 1200;
            const time = parseFloat(loanTenure);
            const emiValue = Math.round((principle / time) + (principle * rate));
            const totalPayment = emiValue * time;
            const totalInterestPayable = totalPayment - principle;

            setEmi(emiValue);
            setTotalInterest(totalInterestPayable);
            setTotalPayment(totalPayment);

            let _chart = chartInfo;
            _chart = {
                labels: ['Principal', 'Interest'],
                datasets: [
                    {
                        label: "Amount",
                        data: [totalPayment, totalInterestPayable],
                        backgroundColor: ['#004466', '#c21200'],
                        borderColor: ['#d9e3ed', '#d9e3ed'],
                        borderWidth: 1,
                    },
                ]
            };

            setChartInfo(_chart);

        } else {
            ResetValues();
        }
    };

    return (
        <>
            <Container>
                <div id="container" >
                    <div id={contentId}>
                        {!token &&
                            <div id="heroImg_loan">
                                <div className="inPgBcShadeBlk">
                                    <div className="inPgBcCrossBlk">
                                        <div className="widthfix_10px">
                                            <ul className="bc_IIcol">
                                                <li className="bc_IIcol">
                                                    <div className="inPgBcHalfBlk">
                                                        <div className="inPgBcTextBlk">
                                                            <Link to="/"><div className="bcMainTxt">Home</div></Link>
                                                            <div className="bcSubTxt">/ EMI Calculator</div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="bc_IIcol">
                                                    <ul className="bcPointsSlanting">
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">Shows the repayment amount for the loan</li>
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">Calculate the EMI to pay every month</li>
                                                        <li className="bcPointsSlanting"></li>
                                                        <li className="bcPointsSlanting">Shows the total interest to be paid</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="emiCalculator">
                            <div className="widthfix_10px">
                                <div className="emiCalcHead">EMI Calculator</div>
                                <div className="emiCalcDesc">Utilize our user-friendly loan calculator to calculate your monthly payments. <br />Use this as an initial step towards gaining a better understanding of your loan application.</div>

                                <ul className="emiCalcIIcol">
                                    <li className="emiCalcIIcol">
                                        <div className="emiCalcShadowBlk">
                                            <div className="formContentHead">Loan Amount</div><div className="formMandatory">*</div>
                                            <div className="emiTxtField">
                                                <div className="rupeeIcon"></div>
                                                <input
                                                    autoComplete={Helper.TimeStamp()}
                                                    type="text"
                                                    id="loanAmount"
                                                    value={loanAmount}
                                                    onChange={(e) => setLoanAmount(e.target.value)}
                                                />
                                            </div>
                                            <div className="formContentHead">Interest Rate (%)</div><div className="formMandatory">*</div>
                                            <div className="emiTxtField">
                                                <div className="intRateIcon"></div>
                                                <input
                                                    autoComplete={Helper.TimeStamp()}
                                                    type="text"
                                                    id="interestRate"
                                                    value={interestRate}
                                                    onChange={(e) => setInterestRate(e.target.value)}
                                                />
                                            </div>
                                            <div className="formContentHead">Loan Tenure (in months)</div><div className="formMandatory">*</div>
                                            <div className="emiTxtField">
                                                <div className="timeIcon"></div>
                                                <input
                                                    autoComplete={Helper.TimeStamp()}
                                                    type="text"
                                                    id="loanTenure"
                                                    value={loanTenure}
                                                    onChange={(e) => setLoanTenure(e.target.value)}
                                                />
                                            </div>
                                            <button onClick={calculateEmi} className="emi_button">Calculate EMI</button>
                                        </div>
                                    </li>
                                    <li className="emiCalcIIcol">
                                        <div className="emiCalcShadowBlk">
                                            <ul className="emiCalcResultIICol">
                                                <li className="emiCalcResultIICol">
                                                    <div className="formContentHead">Monthly EMI is:</div>
                                                    <div className="formContentText"> {emi}</div>
                                                    <div className="emiSeparator"></div>
                                                    <div className="formContentHead">Total Interest Payable:</div>
                                                    <div className="formContentText">{totalInterest}</div>
                                                    <div className="emiSeparator"></div>
                                                    <div className="formContentHead">Total Payment (Principal + Interest):</div>
                                                    <div className="formContentText">{totalPayment}</div>
                                                </li>
                                                <li className="emiCalcResultIICol">
                                                    <div className="formContentHead" style={{ width: "250px", position: "relative" }}>
                                                        <PieChart
                                                            data={chartInfo} />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                                <div className="emiDisclaimer">
                                    The online EMI calculator provided is intended for informational purposes only. The calculated results are approximate and may vary based on the input parameters and any underlying assumptions. It is important to note that the calculated EMI values do not represent a binding agreement or a guarantee of loan approval. Users are advised to consult with financial experts or authorized lending institutions for accurate and personalized loan calculations and information. The creators of this EMI calculator shall not be held liable for any errors, omissions, or damages arising from the use of this tool.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;