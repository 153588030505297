import API from "./api";

const SignIn = async (input) => {
    try {
        const res = await API.post("auth", input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const IsEmailEXist = async (input) => {
    try {
        const res = await API.get(`auth/${input}`);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

export { IsEmailEXist, SignIn };
