import { React, Container, Link } from "common";
import HtmlParse from 'html-react-parser';
import { LookUpApi } from "services";

const Component = () => {

    const [initlized, setInitlized] = React.useState(false);
    const [reAlign, setReAlign] = React.useState(false);
    const [rows, setRows] = React.useState([]);

    React.useEffect(() => {
        if (reAlign) {
            const rootElm = Object.values(document.getElementById("branchlist").querySelectorAll("li.branchIVcol"));
            if (rootElm) {
                const childs = rootElm.map((x) => x.childNodes[0]);
                const _heights = childs.map((x) => x.clientHeight);
                const max = Math.max.apply(null, _heights);
                for (let item of childs) item.style.height = `${max}px`;
            }
        }
    }, [reAlign]);

    const FetchResults = async () => {
        global.Busy(true);
        let rslt = await LookUpApi.GetBranches();
        if (rslt.status === 100) {
            setRows(rslt.data);
            setReAlign(true);
        }
        global.Busy(false);
    };

    if (initlized) {
        setInitlized(false);
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    return (
        <>
            <Container>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div id="heroImg_loan">
                            <div className="inPgBcShadeBlk">
                                <div className="inPgBcCrossBlk">
                                    <div className="widthfix_10px">
                                        <ul className="bc_IIcol">
                                            <li className="bc_IIcol">
                                                <div className="inPgBcHalfBlk">
                                                    <div className="inPgBcTextBlk">
                                                        <Link to="/"><div className="bcMainTxt">Home</div></Link>
                                                        <div className="bcSubTxt">/ MCPL Branches</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="bc_IIcol">
                                                <ul className="bcPointsSlanting">
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Provides buyers with increased confidence</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Speed up the complete loan process</li>
                                                    <li className="bcPointsSlanting"></li>
                                                    <li className="bcPointsSlanting">Give buyers more negotiating power</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="branch">
                            <div className="widthfix_10px">
                                <ul className="branchIVcol" id="branchlist">
                                    {rows && rows.map((x) => (
                                        <li key={x._id} className="branchIVcol">
                                            <div className="branchShadowBlk">
                                                <div className="branchType">{x.branchtype}</div>
                                                <ul className="branchIconContent">
                                                    <li className="branchIconContent">
                                                        <div className="branchLocationIcon"></div>
                                                    </li>
                                                    <li className="branchIconContent">
                                                        <div className="branchLoc">{x.branchloc}</div>
                                                    </li>
                                                    <li className="branchIconContent">
                                                        <div className="branchAddressIcon"></div>
                                                    </li>
                                                    <li className="branchIconContent">
                                                        {x.branchaddr}
                                                    </li>
                                                    <li className="branchIconContent">
                                                        <div className="branchContactIcon"></div>
                                                    </li>
                                                    <li className="branchIconContent">
                                                        {HtmlParse(x.branchcontact)}
                                                    </li>
                                                    <li className="branchIconContent">
                                                        <div className="branchTimeIcon"></div>
                                                    </li>
                                                    <li className="branchIconContent">
                                                        {x.branchtiming}
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;