import { React, Support, Helper, DropDown, TextInput } from "common";

const RenderEmploymentDetails = (props) => {

    const { row, errors, onInputChanged, onBlurError, onInputClicked } = props;

    const [industryTypes] = React.useState(Support.IndustryTypes());

    let rootpath = 'employmentdetails';

    return (
        <>
            <div className="applyHead_borderBottom">
                Employment / Business Details
            </div>
            <ul className="contentIIIcol">
                <li className="contentIIIcol">
                    <div className="applyContentHead">Employment Type</div><div className="formMandatory">*</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.employmentType`}
                            value={row?.employmentType}
                            onInputChildChanged={onInputChanged}
                            items={Support.EmplomentTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                            dataRequired={true}
                            indexValue={1}
                            requiredMessage={"Employment Type is required"}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Profession Type</div><div className="formMandatory">*</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.professionType`}
                            value={row?.professionType}
                            onInputChildChanged={onInputChanged}
                            items={Support.ProfessionTypes()}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                            dataRequired={true}
                            indexValue={1}
                            requiredMessage={"Profession Type is required"}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Industry Type</div><div className="formMandatory">*</div>
                    <div className="applySelectField">
                        <DropDown
                            path={`${rootpath}.industryType`}
                            value={row?.industryType}
                            onInputChildChanged={onInputChanged}
                            items={industryTypes}
                            displayName={"text"}
                            displayValue={"value"}
                            errors={errors}
                            dataRequired={true}
                            indexValue={1}
                            requiredMessage={"Industry Type is required"}
                        />
                    </div>
                </li>

                <li className="contentIIIcol">
                    <div className="applyContentHead">Name of Company / Business</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.companyName`}
                            value={row?.companyName}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={'Company Name is required'}
                            dataRequired={true}
                            required={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">No. of Years in Service</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.serviceInYears`}
                            value={row?.serviceInYears}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={'No. of Years in Service is required'}
                            dataRequired={true}
                            required={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Designation</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.designation`}
                            value={row?.designation}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            dataRequired={false}
                            required={false}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Office / Business Address</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.officeOrBussinessAddr`}
                            value={row?.officeOrBussinessAddr}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            dataRequired={false}
                            required={false}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Pin Code</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.zip`}
                            value={row?.zip}
                            valuetype={"ZIP"}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={'Pin Code is required'}
                            validationMessage={'Enter valid Pin Code'}
                            dataRequired={true}
                            required={true}
                            validate={true}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">State</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.state`}
                            value={row?.state}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={"State is required"}
                            dataRequired={true}
                            required={true}
                            readOnly={Helper.ToBool(row?.statereadonly)}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">City / Place</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.city`}
                            value={row?.city}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={"City is required"}
                            dataRequired={true}
                            required={true}
                            readOnly={Helper.ToBool(row?.cityreadonly)}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">District</div><div className="formMandatory">*</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.district`}
                            value={row?.district}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            requiredMessage={"City is required"}
                            dataRequired={true}
                            required={true}
                            readOnly={Helper.ToBool(row?.districtreadonly)}
                        />
                    </div>
                </li>
                <li className="contentIIIcol">
                    <div className="applyContentHead">Office Phone</div>
                    <div className="applyTxtField">
                        <TextInput
                            errors={errors}
                            path={`${rootpath}.officePhone`}
                            value={row?.officePhone}
                            valuetype={"NUMBERS"}
                            onInputChildChanged={onInputChanged}
                            onBlurError={onBlurError}
                            onInputClicked={onInputClicked}
                            validationMessage={'Enter valid Office Phone Number'}
                            dataRequired={false}
                            required={false}
                            validate={false}
                        />
                    </div>
                </li>
            </ul>
        </>
    );

}

export default RenderEmploymentDetails;