import { React, Helper, CustomButton, TextInput } from "common";
import { CommonApi, UsersApi } from "services";

const Component = () => {

    const [initlized, setInitlized] = React.useState(false);
    const [row, setRow] = React.useState({});
    const [state, setState] = React.useState(false);
    const [showAadharQuestion, setShowAadharQuestion] = React.useState(true);
    const [verifyAadhar, setVerifyAadhar] = React.useState(null);
    const [verificationComplete, setVerificationComplete] = React.useState(false);
    const [showEkyc, setShowEkyc] = React.useState(null);
    const [refId, setRefId] = React.useState("");
    const [phoneNo, setPhoneNo] = React.useState("");
    const [emailWithoutAadhaar, setEmailWithoutAadhaar] = React.useState(true);

    const [showTimer, setShowTimer] = React.useState(false);
    const [showReSendOTP, setShowReSendOTP] = React.useState(false);
    const [countTimer, setCountTimer] = React.useState(30);

    const [errors, setErrors] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [success, setSuccess] = React.useState(null);

    const OnBlurError = (name, val, clear) => {
        setSuccess(null);
        let _errors = [];
        if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
        if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
        setErrors(_errors);
        setError(val);
    };

    const OnInputChanged = async (path, value, index) => {
        let newRow = Helper.ModifyJsonArrayObject4(row, path, value);
        setRow(newRow);
        setState(!state);
    }

    const OnInputClicked = (name) => {
        setSuccess(null);
        let _err = null;
        if (errors && errors.length > 0) {
            let _findIndex = errors.findIndex((x) => x.field === name);
            if (_findIndex > -1) {
                _err = errors[_findIndex].value;
            }
        }
        setError(_err);
    };

    const OnRecordValidate = (id) => {
        setError("");
        setErrors([]);
        const { errors, valid } = Helper.IsFormValid(id);

        if (!valid) {
            setErrors(errors);
            setError("Fill all required details");
            return false;
        }
        return true;
    };

    const OnGetOtpClicked = async (e) => {
        e.preventDefault();
        setError(null);
        setErrors([]);

        if (!Helper.ToUSAPhone(row?.phoneNumber)) {
            setErrors([{ field: 'phone', value: 'Invalid Phone Number' }]);
            return;
        }

        let _phone = Helper.CleanPhoneNumber(row?.phoneNumber);
        global.Busy(true);
        let rslt = await UsersApi.IsexistAgent({ phoneNumber: row?.phoneNumber });
        global.Busy(false);
        if (rslt.status === 100) {
            setError(rslt.statusText);
            return;
        }
        rslt = await CommonApi.GetOtp("mobile", _phone);
        global.Busy(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        }

        setShowTimer(true);
        setCountTimer(30);
    };

    const OnGetAadharOTP = async () => {
        let rslt, data;

        data = { aadhaar_number: row.aadhar, environment: Helper.environment };

        global.Busy(true);
        rslt = await UsersApi.IsAadhaarValid(data);
        global.Busy(false);
        if (rslt.status === 100 & parseInt(rslt.data) > 0) {
            AadharVerfied();
            return;
        }

        global.Busy(true);
        rslt = await UsersApi.GetAgentAadhaarOtp(data);
        global.Busy(false);

        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        }
        setRefId(rslt.ref_id);

        setShowTimer(true);
        setCountTimer(30);
    };

    const OnlineVerifyClick = async (e) => {
        e.preventDefault();

        let rslt;
        let _data = { ref_id: refId, phoneNumber: phoneNo, aadhaar_number: row.aadhar, otp: row.otp, environment: Helper.environment };

        if (!OnRecordValidate('aadharverify')) return;

        global.Busy(true);
        rslt = await UsersApi.AgentAadhaarVerify(_data);
        global.Busy(false);
        if (rslt.status !== 100) {
            setErrors(rslt.errors);
            setError(rslt.statusText);
            return;
        } else {
            setSuccess(rslt.statusText);
        }

        AadharVerfied();
    };

    const AadharVerfied = async () => {
        setSuccess(true);
        setShowAadharQuestion(true);
        setShowEkyc(null);
        setShowTimer(false);
        setState(!state);
        //with aadhar details mail 
        await UsersApi.sentAgentEmail({
            phoneNumber: phoneNo
            , WithoutAadhaarDetails: false,
            aadhaar_number: row?.aadhar
        });
        setRow({});
    }

    const HandleAadharChoice = async (choice) => {
        setVerifyAadhar(choice);
        setShowEkyc(true);
        //send normal mail
        if (!choice) {
            await UsersApi.sentAgentEmail({
                phoneNumber: phoneNo
                , WithoutAadhaarDetails: true
            });
        } else {
            setEmailWithoutAadhaar(false)
        }


    };

    const OnSubmitClicked = async (e) => {
        e.preventDefault();

        if (!OnRecordValidate('signup')) return;

        setError(null);
        setErrors([]);

        let keyId = Helper.CleanPhoneNumber(row.phoneNumber.toString());

        global.Busy(true);
        let rslt = await CommonApi.ValidateOtp("mobile", keyId, parseInt(row.otp), 56);
        global.Busy(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
            return;
        }

        global.Busy(true);
        rslt = await UsersApi.AddAgent(row);
        global.Busy(false);
        if (rslt.status !== 100) {
            setErrors(rslt.errors);
            setError(rslt.statusText);
            return;
        }

        setPhoneNo(row.phoneNumber);
        setRow({});
        setShowAadharQuestion(false);
        setShowTimer(false);
        setShowReSendOTP(false);
        setState(!state);
    }

    const GetButtonValue = () => {
        if (Helper.ToBool(showTimer)) {
            return countTimer;
        } else if (Helper.ToBool(showReSendOTP)) {
            return "Re-send OTP";
        }

        return "Get OTP";
    }

    if (initlized) {
        setInitlized(false);
        setErrors([]);
        setRow({});
        setError(null);
        setSuccess(null);
        setShowAadharQuestion(true);
        setVerificationComplete(false);
        setShowEkyc(null);
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    React.useEffect(() => {
        let timerInterval;
        if (showTimer) {
            timerInterval = setInterval(() => {
                setCountTimer((prevCount) => {
                    if (prevCount <= 1) {
                        clearInterval(timerInterval);
                        setShowTimer(false);
                        setShowReSendOTP(true);
                        return 0;
                    }
                    return prevCount - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timerInterval);
    }, [showTimer]);

    return (
        <>
            <li id="signup">
                <div className="agentShadowBlk">
                    <div className="agentBlkHead">Agent SignUp</div>
                    <div className="agentBlkDesc">
                        Create your agent account with us and earn commissions on every lead!
                    </div>

                    {Helper.ToBool(showAadharQuestion) && (
                        <>
                            <ul className="agentIIcol">
                                <li>
                                    <div className="form-group">
                                        <label>First Name:</label>
                                        <TextInput
                                            path={`firstName`}
                                            value={row?.firstName}
                                            onInputChildChanged={OnInputChanged}
                                            onBlurError={OnBlurError}
                                            onInputClicked={OnInputClicked}
                                            errors={errors}
                                            requiredMessage={'First Name is required'}
                                            dataRequired={true}
                                            required={true}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <label>Last Name:</label>
                                        <TextInput
                                            path={`lastName`}
                                            value={row?.lastName}
                                            onInputChildChanged={OnInputChanged}
                                            onBlurError={OnBlurError}
                                            onInputClicked={OnInputClicked}
                                            errors={errors}
                                            requiredMessage={'Last Name is required'}
                                            dataRequired={true}
                                            required={true}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <label>Phone:</label>
                                        <div className="getOtpBtnBlk">
                                            <input
                                                type="button"
                                                className="getOtpBtn"
                                                disabled={showTimer}
                                                value={GetButtonValue()}
                                                onClick={(e) => OnGetOtpClicked(e)}
                                            />
                                        </div>
                                        <TextInput
                                            path={`phoneNumber`}
                                            value={row?.phoneNumber}
                                            valuetype={"PHONE"}
                                            onInputChildChanged={OnInputChanged}
                                            onBlurError={OnBlurError}
                                            onInputClicked={OnInputClicked}
                                            errors={errors}
                                            requiredMessage={'Phone Number is required'}
                                            validationMessage={'Enter valid Phone Number'}
                                            dataRequired={true}
                                            required={true}
                                            validate={true}
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="form-group">
                                        <label>OTP:
                                            <div className="invalidOTP" style={{ display: "none" }}>Invalid OTP. Please Retry</div>
                                        </label>
                                        <TextInput
                                            path={`otp`}
                                            value={row?.otp}
                                            onInputChildChanged={OnInputChanged}
                                            onBlurError={OnBlurError}
                                            onInputClicked={OnInputClicked}
                                            errors={errors}
                                            requiredMessage={'OTP is required'}
                                            validationMessage={'Enter valid OTP'}
                                            dataRequired={true}
                                            required={true}
                                            validate={true}
                                        />
                                    </div>
                                </li>
                            </ul>

                            <div className="form-group" style={{ display: "none" }}>
                                <input type="button" className="resendOtpBtn" value="Resend OTP" />
                            </div>

                            <CustomButton type="button" value="Create Account" css="btnRed" onClick={OnSubmitClicked} />
                        </>
                    )}
                    {!Helper.ToBool(showAadharQuestion) &&
                        <>
                            {Helper.IsNullValue(showEkyc) &&
                                <div>
                                    <div className="verifyQ">Do you want to complete the verification process online?</div>
                                    <div className="verifyBtnLabel">
                                        <button
                                            onClick={() => HandleAadharChoice(true)}
                                            className={`verifyButton ${verifyAadhar === true ? 'active' : ''}`}
                                        >
                                            Yes
                                        </button>
                                        <button
                                            onClick={() => HandleAadharChoice(false)}
                                            className={`verifyButton ${verifyAadhar === false ? 'active' : ''}`}
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>
                            }

                            {!Helper.IsNullValue(showEkyc) && (
                                verifyAadhar ? (
                                    <div id="aadharverify">
                                        <ul className="agentIIcol">
                                            <li>
                                                <div className="form-group">
                                                    <label>Aadhar No:</label>
                                                    <div className="getOtpBtnBlk">
                                                        <input
                                                            type="button"
                                                            className="getOtpBtn"
                                                            disabled={showTimer}
                                                            value={GetButtonValue()}
                                                            onClick={(e) => OnGetAadharOTP(e)}
                                                        />
                                                    </div>
                                                    <TextInput
                                                        errors={errors}
                                                        path="aadhar"
                                                        valuetype="AADHAR"
                                                        value={row?.aadhar}
                                                        onInputChildChanged={OnInputChanged}
                                                        onBlurError={OnBlurError}
                                                        onInputClicked={OnInputClicked}
                                                        requiredMessage="Aadhar is required"
                                                        validationMessage="Enter valid Aadhar"
                                                        dataRequired={true}
                                                        required={true}
                                                        validate={true}
                                                    />
                                                </div>
                                            </li>
                                            <li>
                                                <div className="form-group">
                                                    <label>
                                                        OTP:
                                                        <div className="invalidOTP" style={{ display: "none" }}>
                                                            Invalid OTP. Please Retry
                                                        </div>
                                                    </label>
                                                    <TextInput
                                                        path="otp"
                                                        value={row?.otp}
                                                        onInputChildChanged={OnInputChanged}
                                                        onBlurError={OnBlurError}
                                                        onInputClicked={OnInputClicked}
                                                        errors={errors}
                                                        requiredMessage="OTP is required"
                                                        validationMessage="Enter valid OTP"
                                                        dataRequired={true}
                                                        required={true}
                                                        validate={true}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                        <CustomButton
                                            type="button"
                                            value="Verify"
                                            css="btnRed"
                                            onClick={(e) => OnlineVerifyClick(e)}
                                        />
                                    </div>
                                ) : (
                                    <div className="verifyBorderLine">
                                        <div className="agentCode spacer_15padding_top">
                                            Please call us at -<br />
                                            <span className="code">(080) 26496555 </span><br />
                                            to complete the verification process
                                        </div>
                                    </div>
                                )
                            )}

                        </>

                    }
                    {error && <div className="errormessage">{error}</div>}
                    {success &&
                        <div>
                            <div className="agentSuccessMsg">Your account is created successfully.</div>
                            <div className="agentCode"><span className="code">{Helper.CleanPhoneNumber(phoneNo)}</span> is your agent code</div>
                        </div>}

                </div>
            </li >
        </>
    )
}

export default Component;