import { React, TextInput, Helper, UploadHelper } from "common";
import { JobsApi } from "services";
const maxsize = 307200;
const formats = ["PNG", "JPG", "PDF"];

const Component = ({ jobId, noclose, onClosedClicked }) => {

    const [row, setRow] = React.useState({});
    const [state, setState] = React.useState(false);
    const [success, setSuccess] = React.useState(null);
    const [errors, setErrors] = React.useState([]);
    const [error, setError] = React.useState(null);

    const [fileurl, setFileUrl] = React.useState(null);
    const [formatError, setFormatError] = React.useState(null);
    const containerId = Helper.IsNullValue(jobId) ? "jobContainer" : "pJobContainer";

    /* File Upload Methods Starts */

    const OnFileInputChanged = (e) => {
        let _file = e.target.files[0];
        setFormatError(null);
        setFileUrl(null);
        if (!Helper.IsNullValue(_file)) {
            let _ext = _file.name.split(".").pop();
            let _index = formats.findIndex((x) => x === _ext.toUpperCase());
            if (_index > -1) {
                if (_file.size > maxsize) {
                    Helper.NativeInputClick("resumedoc");
                    setFormatError("File size allowed: 300 Kb");
                } else {
                    setFileUrl(e.target.files[0]);
                }
            } else {
                Helper.NativeInputClick("resumedoc");
                setFormatError("Supported Format: " + formats.join(","));
            }
        }
    };

    const OnFileUploadClicked = async (e) => {
        e.preventDefault();
        setError(null);

        if (Helper.IsNullValue(fileurl)) {
            setError("Select file to upload");
            return;
        }

        let _ext = fileurl.name.split(".").pop();
        let guid = Helper.GetGUID();
        let obj = { ext: _ext, ofileName: fileurl.name, nfileName: guid };

        let rslt = await UploadHelper.Upload(fileurl, obj);

        if (rslt.status !== 100) {
            Helper.NativeInputClick("resumedoc");
            setError(rslt.statusText);
            return;
        }

        setFormatError("File uploaded successfully");
        OnInputChanged('resumepath', `${guid}.${_ext}`);
    };

    /* File Upload Methods Ends */

    const OnBlurError = (name, val, clear) => {
        setSuccess(null);
        let _errors = [];
        if (errors && errors.length > 0) _errors = errors.filter((x) => x.field !== name);
        if (Helper.IsNullValue(clear)) _errors.push({ field: name, value: val });
        setErrors(_errors);
        setError(val);
    };

    const OnInputChanged = async (path, value, index) => {
        let newRow = Helper.ModifyJsonArrayObject4(row, path, value);
        setRow(newRow);
        setState(!state);
    }

    const OnInputClicked = (name) => {
        setSuccess(null);
        let _err = null;
        if (errors && errors.length > 0) {
            let _findIndex = errors.findIndex((x) => x.field === name);
            if (_findIndex > -1) {
                _err = errors[_findIndex].value;
            }
        }
        setError(_err);
    };

    const OnRecordValidate = () => {
        setError("");
        setErrors([]);

        const { errors, valid } = Helper.IsFormValid(containerId);

        if (Helper.IsNullValue(fileurl)) {
            errors.push({ field: 'resumedoc', value: 'Resume is required' });
        }

        if (!valid || errors.length > 0) {
            setErrors(errors);
            setError("Fill all required details");
            return false;
        }
        return true;
    };

    const OnSubmitClicked = async (e) => {
        e.preventDefault();
        if (!OnRecordValidate()) return;

        row.jobid = jobId;
        global.Busy(true);
        let rslt = await JobsApi.ApplyJob(row);
        global.Busy(false);
        if (rslt.status !== 100) {
            setErrors(rslt.errors);
            setError(rslt.statusText);
            return;
        } else {
            Helper.NativeInputClick("resumedoc");
            setSuccess("Application submitted successfully");
            setFormatError(null);
            setRow({});
        }
    };

    return (
        <div id={containerId}>
            <div className="jobApplyFormBlk">
                <ul className="jobApplyForm">
                    <li className="jobApplyForm">
                        <div className="jobApplyFormHead">Name<div className="formMandatory">*</div></div>
                        <div className="jobApplyFormTxtField">
                            <TextInput
                                path={`name`}
                                value={row?.name}
                                onInputChildChanged={OnInputChanged}
                                onBlurError={OnBlurError}
                                onInputClicked={OnInputClicked}
                                errors={errors}
                                requiredMessage={'Name is required'}
                                dataRequired={true}
                                required={true}
                                validate={true}
                            />
                        </div>
                    </li>
                    <li className="jobApplyForm">
                        <div className="jobApplyFormHead">Email<div className="formMandatory">*</div></div>
                        <div className="jobApplyFormTxtField">
                            <TextInput
                                path={`email`}
                                value={row?.email}
                                valuetype={"EMAIL"}
                                onInputChildChanged={OnInputChanged}
                                onBlurError={OnBlurError}
                                onInputClicked={OnInputClicked}
                                errors={errors}
                                requiredMessage={'Email-Id is required'}
                                validationMessage={'Enter valid Email-Id'}
                                dataRequired={true}
                                required={true}
                                validate={true}
                            />
                        </div>
                    </li>
                    <li className="jobApplyForm">
                        <div className="jobApplyFormHead">Phone<div className="formMandatory">*</div></div>
                        <div className="jobApplyFormTxtField">
                            <TextInput
                                path={`phone`}
                                value={row?.phone}
                                valuetype={"PHONE"}
                                onInputChildChanged={OnInputChanged}
                                onBlurError={OnBlurError}
                                onInputClicked={OnInputClicked}
                                errors={errors}
                                requiredMessage={'Phone Number is required'}
                                validationMessage={'Enter valid Phone Number'}
                                dataRequired={true}
                                required={true}
                                validate={true}
                            />
                        </div>
                    </li>
                    <li className="jobApplyForm">
                        <div className="jobApplyFormHead">Upload Resume<div className="formMandatory">*</div></div>
                        <div className="jobApplyFormFileUpload">
                            <div className="jobApplyFormUploadBox"
                                style={{
                                    borderColor: Helper.GetErrorBackColor(errors, 'resumedoc'),
                                    paddingLeft: 20,
                                }}>
                                <input
                                    id="resumedoc"
                                    type="file"
                                    name="resumedoc"
                                    onClick={(e) => {
                                        e.target.value = null;
                                        setError(null);
                                    }}
                                    onChange={(e) => OnFileInputChanged(e)}
                                />
                            </div>
                            <div className="jobApplyFormBtnUploadLayout"><input type="submit"
                                className="jobApplyFormBtnUpload" value="Upload" onClick={(e) => OnFileUploadClicked(e)} /></div>
                            {formatError && <div className="jobApplyFormSubmitError">{formatError}</div>}
                        </div>
                    </li>
                </ul>
                <div className="jobApplyForm_Submit_Btn_Layout">
                    <input type="button" className="jobApplyForm_Submit_Btn"
                        value="Submit" onClick={(e) => OnSubmitClicked(e)}
                    />
                    {!noclose &&
                        <input type="button" className="jobApplyForm_Submit_Btn"
                            value="Close" onClick={(e) => onClosedClicked(e)} />
                    }
                </div>
                {success && <div className="successmessage_center" >Our representative will be in touch shortly</div>}
                {error && <div className="errormessage_center">{error}</div>}
            </div>
        </div>
    )
}

export default Component;