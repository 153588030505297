import API from "./api";
import { PDFGeneratorApi } from "common"

const AddApplication = async (input) => {
    try {
        const res = await API.post(`loan`, input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const AddPreApproval = async (input) => {
    try {
        const res = await API.post(`loan/pre`, input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GeneratePDF = async (input) => {
    return new Promise(async (resolve) => {
        let url = `${PDFGeneratorApi}/html/pdf/loanquote`;

        try {
            const res = await fetch(url, {
                method: "POST",
                redirect: 'follow',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(input)
            });

            const json = await res.json();
            if (res.status === 200) {
                return resolve(json);
            }

            return resolve({ status: 900, statusText: json.error.message });

        } catch (error) {
            console.log(error);
            return resolve({ status: 900, statusText: error.message });
        }
    })
}

const GetApplication = async (input) => {
    try {
        const res = await API.get(`loan/getapplication/${input}`);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

export { AddApplication, AddPreApproval, GeneratePDF, GetApplication };
