import { React, Link } from "common";

const Component = () => {
    return (
        <div id="home_bottom_blk">
            <div className="widthfix_10px">
                <ul className="home_V_col">
                    <li className="home_V_col">
                        <Link to="/bikeloan"><ul className="botIconText_blk">
                            <li className="botIcon_blk">
                                <div className="botIcon2"></div>
                            </li>
                            <li className="botText_blk">
                                <div className="botTextHead">Two Wheeler Loan</div>
                                <div className="botTextDesc">A loan to own the motorbike of your choice with easy installments</div>
                            </li>
                        </ul></Link>
                    </li>
                    <li className="home_V_col">
                        <Link to="/goldloan"><ul className="botIconText_blk">
                            <li className="botIcon_blk">
                                <div className="botIcon1"></div>
                            </li>
                            <li className="botText_blk">
                                <div className="botTextHead">Loan Against Gold</div>
                                <div className="botTextDesc">A secured loan taken by pledging the gold articles as collateral</div>
                            </li>
                        </ul></Link>
                    </li>
                    <li className="home_V_col">
                        <Link to="/consumerloan"><ul className="botIconText_blk">
                            <li className="botIcon_blk">
                                <div className="botIcon3"></div>
                            </li>
                            <li className="botText_blk">
                                <div className="botTextHead">Consumer Durable Loan</div>
                                <div className="botTextDesc">To purchase electronic gadgets and household appliances on EMI</div>
                            </li>
                        </ul></Link>
                    </li>
                    <li className="home_V_col">
                        <Link to="/propertyloan"><ul className="botIconText_blk">
                            <li className="botIcon_blk">
                                <div className="botIcon4"></div>
                            </li>
                            <li className="botText_blk">
                                <div className="botTextHead">Loan Against Property</div>
                                <div className="botTextDesc">A secured loan to borrow money by using your property as collateral</div>
                            </li>
                        </ul></Link>
                    </li>
                    <li className="home_V_col">
                        <Link to="/businessloan"><ul className="botIconText_blk">
                            <li className="botIcon_blk">
                                <div className="botIcon5"></div>
                            </li>
                            <li className="botText_blk">
                                <div className="botTextHead">Business <br />Loans</div>
                                <div className="botTextDesc">A loan to meet your growing business urgent financial requirements</div>
                            </li>
                        </ul></Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Component;