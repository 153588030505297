import API from "./api";
import { Session } from "helper";

const GetSignedUrl = async (input) => {
  try {
    const res = await API.post(`doc/getsignedurl`, input);
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

const GetDocumentUrl = async (input) => {
  let token = await Session.GetHeader();
  try {
    const res = await API.post(`doc/getdocurl`, input, { headers: token });
    return res.data;
  } catch (err) {
    return { statusText: err.message, status: 900 };
  }
};

export {
  GetSignedUrl,
  GetDocumentUrl
};
